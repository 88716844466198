export const validateDate = (value: string) => {
  const date = value.split('/');
  const newDate = `${date[1]}/${date[0]}/${date[2]}`;
  const today = new Date();
  const date1 = new Date(newDate);
  const diff = date1.getTime() - today.getTime();
  const diffDays = Math.ceil(diff / (1000 * 3600 * 24));
  if (diffDays > 0 && diffDays <= 75) {
    return true;
  }
  return false;
};

export const formatDate = (value: string | undefined | null) => {
  if (value) {
    const date = value?.split('/');
    const newDate = `${date[1]}/${date[0]}/${date[2]}`;
    return newDate;
  }
};
