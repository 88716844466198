import {
  Box,
  Button,
  Card,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Cancel, Check, Close, Help } from '@material-ui/icons';
import { ILegalPersonResponse } from '../types/ILegalPersonResponse';
import { IPrivateIndividualResponse } from '../types/IPrivateIndividualResponse';
import { ICheckAssertivaResponse } from '../utils/checkAssertiva';
import ResponseModal from './IResponseModal';

export interface IAssertivaVerificationCardProps {
  response: ICheckAssertivaResponse | undefined;
  setOpenResponseModal: (open: boolean) => void;
  openResponseModal: boolean;
  peopleResponse: IPrivateIndividualResponse | undefined;
  openCard: React.Dispatch<React.SetStateAction<boolean>>;
  isPeopleResponse: boolean;
  legalResponse: ILegalPersonResponse | undefined;
}

const AssertivaVerificationCard: React.FC<IAssertivaVerificationCardProps> = ({
  response,
  setOpenResponseModal,
  openResponseModal,
  peopleResponse,
  legalResponse,
  openCard,
}) => (
  <Card
    style={{
      marginTop: '10px',
    }}
  >
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <IconButton sx={{ mr: 1 }} onClick={() => openCard(false)}>
        <Close />
      </IconButton>
    </Box>

    <Box p={2}>
      <Box
        mt={-1}
        mb={1}
        display="flex"
        flexDirection="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Typography variant="h6">Verifição da Assertiva</Typography>
        <Tooltip title="Para segurança das nossas vendas, realizamos a verificação das informações dos compradores na Plataforma Assertiva. Caso você identifique algo estranho, clique no botão da verificação para analisar os dados e conferir se o comprador possui identidade correta. Caso contrário, procure seu líder antes de continuar a venda.">
          <IconButton size="small">
            <Help />
          </IconButton>
        </Tooltip>
      </Box>
      <Box
        mt={-1}
        mb={1}
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Button
          variant="outlined"
          color="primary"
          sx={{
            color: response?.name === true ? 'green' : 'orange',
            borderColor: response?.name === true ? 'green' : 'orange',
          }}
          size="small"
          startIcon={
            response?.name === true ? (
              <Check fontSize="small" />
            ) : (
              <Cancel fontSize="small" />
            )
          }
          style={{
            marginTop: '10px',
          }}
          onClick={() => {
            setOpenResponseModal(true);
          }}
        >
          {response?.name === true ? 'Nome Verificado' : 'Conferir o nome'}
        </Button>
        <Button
          variant="outlined"
          color="primary"
          sx={{
            color: response?.cpfCnpj === true ? 'green' : 'orange',
            borderColor: response?.cpfCnpj === true ? 'green' : 'orange',
          }}
          size="small"
          startIcon={
            response?.cpfCnpj === true ? (
              <Check fontSize="small" />
            ) : (
              <Cancel fontSize="small" />
            )
          }
          style={{ marginTop: '10px' }}
          onClick={() => {
            setOpenResponseModal(true);
          }}
        >
          {response?.cpfCnpj === true
            ? 'CPF/CNPJ Verificado'
            : 'Conferir CPF/CNPJ'}
        </Button>
        <Button
          variant="outlined"
          color="primary"
          sx={{
            color: response?.address === true ? 'green' : 'orange',
            borderColor: response?.address === true ? 'green' : 'orange',
          }}
          size="small"
          startIcon={
            response?.address === true ? (
              <Check fontSize="small" />
            ) : (
              <Cancel fontSize="small" />
            )
          }
          style={{ marginTop: '10px' }}
          onClick={() => {
            setOpenResponseModal(true);
          }}
        >
          {response?.address === true
            ? 'Endereço Verificado'
            : 'Conferir endereço'}
        </Button>

        <Button
          variant="outlined"
          color="primary"
          sx={{
            color: response?.email === true ? 'green' : 'orange',
            borderColor: response?.email === true ? 'green' : 'orange',
          }}
          size="small"
          startIcon={
            response?.email === true ? (
              <Check fontSize="small" />
            ) : (
              <Cancel fontSize="small" />
            )
          }
          style={{ marginTop: '10px' }}
          onClick={() => {
            setOpenResponseModal(true);
          }}
        >
          {response?.email === true ? 'Email Verificado' : 'Conferir email'}
        </Button>
      </Box>

      <ResponseModal
        handleClose={() => {
          setOpenResponseModal(false);
        }}
        open={openResponseModal}
        responseCPF={peopleResponse}
        responseCNPJ={legalResponse}
      />
    </Box>
  </Card>
);

export default AssertivaVerificationCard;
