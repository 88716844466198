import { Box, Fab } from '@material-ui/core';
import React, { useEffect } from 'react';
import DownloadIcon from '@material-ui/icons/Download';

interface DownloadProps {}

const Download: React.FC<DownloadProps> = () => (
  <>
    <style>{'@media print {.hide{display: none;}}'}</style>
    <Fab
      variant="extended"
      onClick={() => window.print()}
      className="hide"
      style={{
        position: 'fixed',
        bottom: '1rem',
        right: '1rem',
      }}
    >
      <Box mr={1}>
        <DownloadIcon />
      </Box>
      Imprimir PDF
    </Fab>
  </>
);

export default Download;
