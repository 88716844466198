import { FC } from 'react';
import { useQuery } from 'react-query';
import { getAllCustomers } from 'src/modules/customers/services/getAllCustomers';
import { getAllSources } from 'src/modules/customers/services/getAllSources';
import { getAllComboProducts } from 'src/modules/erpProducts/services/getAllCombos';
import { getAllErpProductss } from 'src/modules/erpProducts/services/getAllErpProductss';
import OrderForm from '../forms/OrderForm';

export const OrderMinimal: FC = () => {
  const productsQuery = useQuery('getAllErpProductss', getAllErpProductss, {
    cacheTime: 15 * 60 * 1000,
    staleTime: 15 * 60 * 1000,
    refetchInterval: 15 * 60 * 1000,
  });
  const customersQuery = useQuery('getAllCustomers', getAllCustomers);
  const combosQuery = useQuery('getAllComboProducts', getAllComboProducts);
  const sources = useQuery('getAllSources', getAllSources);

  const productsFiltered = productsQuery.data?.data.filter(
    (product) => product.pro_estoqueatual !== 0,
  );

  return (
    <OrderForm
      products={productsFiltered || []}
      customers={customersQuery?.data?.data || []}
      combos={combosQuery?.data?.data || []}
      sources={sources?.data?.data || []}
      customersQuery={customersQuery}
    />
  );
};

export default OrderMinimal;
