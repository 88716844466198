import React, { createContext } from 'react';
import useLocalStorage from 'src/hooks/useLocalStorage';

export interface ILoggedInCustomer {
  name: string;
  email: string;
  picture: string;
  provider: 'google' | 'facebook' | 'linkedin';
}

interface ICustomerAuthProvider {
  customer: ILoggedInCustomer | undefined;
  setCustomer: (customer: ILoggedInCustomer | undefined) => void;
}

export const CustomerAuthContext = createContext<ICustomerAuthProvider>({
  customer: undefined,
  setCustomer: () => {},
});

export const CustomerAuthProvider: React.FC = ({ children }) => {
  const [customer, setCustomer] = useLocalStorage<
    ILoggedInCustomer | undefined
  >(
    'authCustomerState',
    undefined,
    60 * 60 * 23, // Stays on the storage for 23 hours
  );

  const globals: ICustomerAuthProvider = {
    customer,
    setCustomer,
  };

  return (
    <CustomerAuthContext.Provider value={globals}>
      {children}
    </CustomerAuthContext.Provider>
  );
};
