import * as Sentry from '@sentry/react';
import { AUTHENTICATE_USER, IAuthContext } from '../AuthInterface';

export function authenticateUserAction(
  state: IAuthContext,
  action: AUTHENTICATE_USER,
): IAuthContext {
  console.log('authenticateUserAction', action);
  const loggedUser = action.loggedUser;

  Sentry.setUser({
    id: loggedUser.id,
    email: loggedUser.email,
    username: loggedUser.name,
    segment: loggedUser.role?.slug,
  });

  console.log('Sentry.setUser', {
    id: loggedUser.id,
    email: loggedUser.email,
    username: loggedUser.name,
    segment: loggedUser.role?.slug,
  });

  return {
    ...state,
    loggedUser: action.loggedUser,
    // firebaseUser: action.firebaseUser,
  };
}
