import { useEffect } from 'react';
import type { FC } from 'react';
import NProgress from 'nprogress';
import { Box, CircularProgress } from '@material-ui/core';

const LoadingScreen: FC = () => {
  useEffect(() => {
    NProgress.start();

    return (): void => {
      NProgress.done();
    };
  }, []);

  return (
    <Box
      display="flex"
      minHeight="20em"
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress />
    </Box>
  );
};

export default LoadingScreen;
