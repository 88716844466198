import { Box, Card, Step, StepLabel, Stepper } from '@material-ui/core';
import * as React from 'react';

const steps = [
  'Cliente verificado na assertiva',
  'Verificar score do cliente',
  'Cliente aprovado',
];

export interface IStepperProps {
  activeStep: number;
}

export const HorizontalSteps: React.FC<IStepperProps> = ({ activeStep }) => (
  <Card>
    <Box sx={{ width: '100%', marginTop: '10px', marginBottom: '10px' }}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label} active={false}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  </Card>
);
