import { SET_FIREBASE_USER, IAuthContext } from '../AuthInterface';

export function setFirebaseUserAction(
  state: IAuthContext,
  action: SET_FIREBASE_USER,
): IAuthContext {
  return {
    ...state,
    firebaseUser: action.firebaseUser,
  };
}
