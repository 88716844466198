import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-quill/dist/quill.snow.css';
import 'nprogress/nprogress.css';
// import { AuthProvider } from './contexts/JWTContext';
import { StrictMode } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { ReactQueryDevtools } from 'react-query/devtools';
import { QueryClient, QueryClientProvider } from 'react-query';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import StyledEngineProvider from '@material-ui/core/StyledEngineProvider';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { SettingsProvider } from './contexts/SettingsContext';
import AuthProvider from './contexts/AuthContext/AuthContext';
import LayoutProvider from './contexts/LayoutContext/LayoutContext';
import App from './App';
import { CustomerAuthProvider } from './contexts/CustomerAuthContext';

Sentry.init({
  dsn: 'https://42542a3c3c2c4b478172834f79fab700@o1303896.ingest.sentry.io/4504248618057728',
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
      maskAllInputs: false,
    }),
  ],
  environment: process.env.NODE_ENV,
  enabled: process.env.NODE_ENV === 'production',
  tracesSampleRate: 1.0,
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      cacheTime: 1000 * 60 * 60 * 4, // 4 hours
      keepPreviousData: true,
    },
  },
});

ReactDOM.render(
  <StrictMode>
    <HelmetProvider>
      <StyledEngineProvider injectFirst>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <CustomerAuthProvider>
            <QueryClientProvider client={queryClient}>
              <SettingsProvider>
                <BrowserRouter>
                  <LayoutProvider>
                    <AuthProvider>
                      <App />
                      <ReactQueryDevtools initialIsOpen />
                    </AuthProvider>
                  </LayoutProvider>
                </BrowserRouter>
              </SettingsProvider>
            </QueryClientProvider>
          </CustomerAuthProvider>
        </LocalizationProvider>
      </StyledEngineProvider>
    </HelmetProvider>
  </StrictMode>,
  document.getElementById('root'),
);
