/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useMemo } from 'react';
import { Box, Button, TableCell, TableRow } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import CurrencyInput from 'src/components/forms/MaskedTextField/types/Currency';
import { convertCentsToLocale } from 'src/utils/moneyConverter';
import { UseFieldArrayRemove, UseFormReturn } from 'react-hook-form';
import { ErpProductsInterface } from 'src/modules/erpProducts/types/ErpProductsInterface';
import {
  calculateDiscountPercentOfTotal,
  calculateDiscountValueOfTotal,
  calculateIndividualProductDiscount,
} from '../utils/calculateDiscount';
import { TextfieldController } from '../../../components/forms/TextfieldController';
import { IOrderForm } from '../types/OrderFormInterface';

interface OrderFormRowProps {
  product: ErpProductsInterface;
  index: number;
  methods: UseFormReturn<IOrderForm, any>;
}

export const OrderFormRow: React.FC<OrderFormRowProps> = ({
  product,
  index,
  methods,
}) => {
  const { watch, setValue, control } = methods;

  // @ts-ignore
  const quantity = parseInt(watch(`products.${index}.quantity`).toString(), 10);
  // @ts-ignore
  const percent = parseFloat(watch(`products.${index}.off_percent`).toString());
  // @ts-ignore
  const offvalue = Number.isNaN(parseFloat(watch(`products.${index}.off_value`)?.toString())) ? watch(`products.${index}.off_value`)?.toString() : parseFloat(watch(`products.${index}.off_value`)?.toString());

  const discount = calculateIndividualProductDiscount({
    product,
    off_percent: percent,
    off_value: offvalue,
    quantity,
  });

  const totalValue = useMemo(
    () => product.prov_valor * quantity - discount,
    [discount, product.prov_valor, quantity],
  );

  const url = useMemo(
    () => product.images.find((image) => image.is_main === true)?.url,
    [product.images],
  );

  const setDiscountValue = (value: number) => {
    const calc = calculateDiscountValueOfTotal({
      product,
      off_percent: value,
      quantity,
    });

    // @ts-ignore
    setValue(`products.${index}.off_value`, calc);
  };

  const setDiscountPercent = (value: number) => {
    const calc = calculateDiscountPercentOfTotal({
      product,
      off_value: value,
      quantity,
    });

    // @ts-ignore
    setValue(`products.${index}.off_percent`, calc);
  };

  return (
    <TableRow key={product.pro_descr}>
      <TableCell component="th" scope="row">
        <img src={url} alt="" width="50px" />
      </TableCell>
      <TableCell component="th" scope="row">
        {product.pro_descr.charAt(0).toUpperCase() + product.pro_descr.slice(1)}
      </TableCell>
      <TableCell component="th" scope="row">
        {convertCentsToLocale(product.prov_valor)}
      </TableCell>
      <TableCell align="left">
        <TextfieldController
          control={control}
          name={`products.${index}.quantity`}
          variant="standard"
          type="number"
          rules={{ required: true, min: 1 }}
        />
      </TableCell>

      <TableCell align="left">
        <TextfieldController
          control={control}
          name={`products.${index}.off_value`}
          variant="standard"
          currency="cents"
          inputComponent={CurrencyInput}
          rules={{
            onChange(event) {
              setDiscountPercent(event.target.value);
            },
          }}
        />
      </TableCell>

      <TableCell align="left">
        <TextfieldController
          control={control}
          name={`products.${index}.off_percent`}
          variant="standard"
          type="number"
          rules={{
            min: 0,
            max: 100,
            onChange(event) {
              setDiscountValue(parseFloat(event.target.value));
            },
          }}
        />
      </TableCell>
      <TableCell component="th" scope="row">
        {convertCentsToLocale(totalValue)}
      </TableCell>
    </TableRow>
  );
};

export default OrderFormRow;
