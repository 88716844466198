import { axiosGET, CustomAxiosResponse } from 'src/services/axiosService';
import { ErpProductsInterface } from './../types/ErpProductsInterface';

export const getAllErpProductss = async (): Promise<
  CustomAxiosResponse<ErpProductsInterface[]>
> => {
  try {
    const endpoint = `${process.env.REACT_APP_PUBLIC_BACKEND}/products`;

    const response = await axiosGET<ErpProductsInterface[]>(endpoint);

    return {
      ...response,
      data: response.data,
      message: 'Pronto!',
    };
  } catch (error: any) {
    throw new Error(
      error?.message ? error.message : 'Ocorreu um erro, tente novamente.',
    );
  }
};
