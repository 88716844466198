import { convertFormattedMoneyToCents } from "src/utils/moneyConverter";

type IParse = number | string | undefined | null;

export const parsePercent = (off_percent: IParse) =>
  typeof off_percent === "number"
    ? off_percent
    : parseFloat(`${off_percent || 0}`);

export const parseValue = (off_value: IParse) =>
  typeof off_value === "number"
    ? Math.round(off_value)
    : convertFormattedMoneyToCents(off_value || 0);