/* eslint-disable array-callback-return */
/* eslint-disable max-len */
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Modal,
  Typography,
} from '@material-ui/core';
import {
  convertCentsToFloat,
  convertCentsToLocale,
} from 'src/utils/moneyConverter';
import {
  OrderProductInterface,
  Product,
} from 'src/services/public/order-products/types/getOrderInterface';
import { ICustomer } from 'src/modules/customers/types/CustomerInterface';
import { DealResponse } from 'src/services/jetadmin.service';
import { GetRenegotiationInfo } from 'src/types/jetadmin-renegotiation';

interface SignatureTermsModalInterface {
  renegociation: GetRenegotiationInfo;
}
export const SignatureTermsModal = ({
  renegociation,
}: SignatureTermsModalInterface) => {
  const { template } = renegociation;

  const NOME_COMPLETO = template.find(
    ({ name }) => name === 'nome_completo',
  )?.content;
  const CPF_NUMERO = template.find(
    ({ name }) => name === 'cpf_numero',
  )?.content;
  const ENDERECO_MORADOR = template.find(
    ({ name }) => name === 'endereco_morador',
  )?.content;
  const NUMERO_MORADOR = template.find(
    ({ name }) => name === 'numero_morador',
  )?.content;
  const BAIRRO_MORADOR = template.find(
    ({ name }) => name === 'bairro_morador',
  )?.content;
  const CIDADE_MORADOR = template.find(
    ({ name }) => name === 'cidade_morador',
  )?.content;
  const ESTADO_MORADOR = template.find(
    ({ name }) => name === 'estado_morador',
  )?.content;
  const CEP_MORADOR = template.find(
    ({ name }) => name === 'cep_morador',
  )?.content;
  const NUM_NOTAFISCAL = template.find(
    ({ name }) => name === 'num_notafiscal',
  )?.content;
  const VALOR_TOTAL = template.find(
    ({ name }) => name === 'valor_total',
  )?.content;
  const VALOR_TOTAL_EXTENSO = template.find(
    ({ name }) => name === 'valor_total_extenso',
  )?.content;
  const DATA_INICIO_VENCIMENTO = template.find(
    ({ name }) => name === 'data_inicio_vencimento',
  )?.content;
  const DATA_FIM_VENCIMENTO = template.find(
    ({ name }) => name === 'data_fim_vencimento',
  )?.content;
  const VALOR_PARCELA = template.find(
    ({ name }) => name === 'valor_parcela',
  )?.content;
  const NUMERO_PARCELAS = template.find(
    ({ name }) => name === 'numero_parcelas',
  )?.content;
  const VALOR_PARCELADO = template.find(
    ({ name }) => name === 'valor_parcelado',
  )?.content;
  const DATE = template.find(({ name }) => name === 'date')?.content;

  return (
    <Card>
      <CardContent>
        {/* align on center */}
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Termo de confissão de dívida
          </Typography>
        </Box>
        <Typography id="modal-modal-description" variant="body1" sx={{ mt: 2 }}>
          Eu, {NOME_COMPLETO}, inscrita no CPF/MF sob nº {CPF_NUMERO}, com
          residência na {ENDERECO_MORADOR}, {NUMERO_MORADOR}, {BAIRRO_MORADOR},{' '}
          {CIDADE_MORADOR} - {ESTADO_MORADOR}, CEP: {CEP_MORADOR}, CONFESSO a
          existência de um débito para com a empresa EDITORA NAPOLEÃO LTDA; esta
          devidamente inscrita no CNPJ sob nº 06.228.693/0001-50; oriundo da(s)
          Nota(s) Fiscal(is) {NUM_NOTAFISCAL}, no valor de {VALOR_TOTAL} (
          {VALOR_TOTAL_EXTENSO}) referente às parcelas com vencimentos de{' '}
          {DATA_INICIO_VENCIMENTO} à {DATA_FIM_VENCIMENTO} que serão atualizadas
          pela correção monetária do IPCA, mais mora 1% a.m. e mais multa de
          20%, serão pagas da seguinte forma: Valor de {VALOR_PARCELA} em{' '}
          {NUMERO_PARCELAS} parcelas de {VALOR_PARCELADO}, os pagamentos
          ocorrerão via boleto bancário encaminhado pela credora EDITORA
          NAPOLEÃO LTDA. A partir da assinatura do presente instrumento, o
          DEVEDOR terá o prazo de 10 dias corridos para realização do pagamento
          da primeira parcela ora convencionada, sendo que a partir da data do
          pagamento, serão lançadas, de maneira consecutiva, as próximas
          parcelas para o mesmo dia dos meses subsequentes. Em caso de ausência
          de pagamento pontual ou atraso no pagamento da primeira parcela, na
          forma da cláusula anterior, considerar-se-á como data de vencimento o
          primeiro dia da contagem ora prevista, sendo exigíveis as demais
          parcelas para o mesmo dia dos meses subsequentes, de maneira
          consecutiva. Caso verificada a inadimplência, a dívida será declarada
          vencida antecipadamente com a imposição de correção monetária de
          acordo com a variação do IPCA acrescidos da multa de 20% (vinte por
          cento), além de juros de 1% (um por cento) ao mês. Informamos ainda,
          que o não contato na data aprazada, implicará na imediata tomada das
          medidas legais da espécie, na esfera civil.
        </Typography>
        <Typography id="modal-modal-description" variant="body1" sx={{ mt: 2 }}>
          Para dirimir quaisquer controvérsias oriundas do contrato, as partes
          elegem o foro da comarca de Nova Odessa, Estado de São Paulo, por mais
          privilegiado que outro possa existir.
        </Typography>
        <Typography id="modal-modal-description" variant="body1" sx={{ mt: 2 }}>
          Por estarem assim justos e contratados, firmam o presente instrumento,
          em 2 (duas) vias de igual teor, juntamente a 2 (duas) testemunhas.
        </Typography>

        <Typography id="modal-modal-description" variant="body1" sx={{ mt: 2 }}>
          Nova Odessa, {DATE}.
        </Typography>
      </CardContent>
    </Card>
  );
};

export default SignatureTermsModal;

function formatStreet(street?: string) {
  const tmpStreet = street?.split(',')[0];

  // if street includes Rua or rua, remove it
  if (tmpStreet?.includes('Rua') || tmpStreet?.includes('rua')) {
    return tmpStreet?.replace('Rua', '').replace('rua', '');
  }

  return tmpStreet;
}
