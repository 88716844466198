import { FC, useMemo } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import { Avatar, Box, Divider, Drawer, Typography } from '@material-ui/core';
import type { Theme } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import {
  ShoppingCart,
  Groups,
  Badge,
  ShoppingBasket,
  LocalAtm,
  AutoStories,
  AttachEmail,
  Analytics,
  AccountBalance,
  InsertChart,
} from '@material-ui/icons';
import useAuth from '../../hooks/useAuth';

import ChartSquareBarIcon from '../../icons/ChartSquareBar';

import Logo from '../Logo';
import NavSection from '../NavSection';
import Scrollbar from '../Scrollbar';

interface DashboardSidebarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

export interface SectionInterface {
  title: string;
  show: boolean;
  items: Item[];
}

export interface Item {
  title: string;
  path: string;
  icon: JSX.Element;
  children?: Child[];
  show: boolean;
}

export interface Child {
  title: string;
  path: string;
  show: boolean;
}

const DashboardSidebar: FC<DashboardSidebarProps> = (props) => {
  const location = useLocation();

  const { onMobileClose, openMobile } = props;

  const {
    user,
    isAdmin,
    isSeller,
    isComercial,
    isFinancial,
    isMarketing,
    isADMFinancial,
    isCollector,
  } = useAuth();

  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));

  const sections: SectionInterface[] = useMemo(
    () => [
      {
        title: 'Dashboard',
        show: isAdmin || isSeller || isComercial || isFinancial,
        items: [
          {
            title: 'Dashboard',
            path: '/',
            icon: <ChartSquareBarIcon fontSize="small" />,
            show:
              isAdmin || isSeller || isComercial || isFinancial || isMarketing,
          },
        ],
      },
      {
        title: 'Marketing',
        show: isMarketing,
        items: [
          {
            title: 'Marketing',
            path: '/marketing',
            icon: <AttachEmail />,
            show: isMarketing,
          },
        ],
      },
      {
        title: 'Gestão de cobrança',
        show: isCollector,
        items: [
          {
            title: 'Cobranças',
            path: '/gestao',
            icon: <AccountBalance />,
            show: isCollector,
          },
        ],
      },
      {
        title: 'Gestão Financeira',
        show: isADMFinancial,
        items: [
          {
            title: 'Dashboard Financeiro',
            path: '/dashboard-financeiro',
            icon: <Analytics />,
            show: isADMFinancial,
          },
          {
            title: 'Gestão de Cobrança',
            path: '/gestao',
            icon: <AccountBalance />,
            show: isADMFinancial,
          },
          {
            title: 'Gestão de Pagamentos',
            path: '/gestao/pagamentos',
            icon: <LocalAtm />,
            show: isADMFinancial,
          },
          {
            title: 'Business Intelligence',
            path: '/gestao/all',
            icon: <InsertChart />,
            show: isADMFinancial,
          },
        ],
      },
      {
        title: 'Produtos',
        show: isAdmin || isSeller || isComercial || isFinancial || isMarketing,
        items: [
          {
            title: 'Produtos',
            icon: <ShoppingBasket fontSize="small" />,
            path: '/produtos',
            show:
              isAdmin || isSeller || isComercial || isFinancial || isMarketing,
          },
        ],
      },
      {
        title: 'Orçamentos',
        show: isAdmin || isSeller || isComercial || isFinancial,
        items: [
          {
            title: 'Clientes',
            icon: <Groups fontSize="small" />,
            path: '/clientes',
            show: isAdmin || isSeller,
          },
          {
            title: 'Orçamentos',
            icon: <ShoppingCart fontSize="small" />,
            path: '/orcamentos',
            show: isAdmin || isSeller || isComercial || isFinancial,
          },
        ],
      },
      // {
      //   title: 'Comercial',
      //   show: isAdmin || isComercial,
      //   items: [
      //     {
      //       title: 'Aprovação Comercial',
      //       icon: <Badge fontSize="small" />,
      //       path: '/aprovacoes/comercial',
      //       show: isAdmin || isComercial,
      //     },
      //   ],
      // },
      // {
      //   title: 'Financeiro',
      //   show: isAdmin || isFinancial,
      //   items: [
      //     {
      //       title: 'Aprovação Financeira',
      //       icon: <LocalAtm fontSize="small" />,
      //       path: '/aprovacoes/financeiro',
      //       show: isAdmin || isFinancial,
      //     },
      //   ],
      // },

      {
        title: 'Gestão',
        show: isAdmin || isComercial,
        items: [
          // {
          //   title: 'Conselhos',
          //   icon: <Badge fontSize="small" />,
          //   path: '/conselhos',
          //   show: isAdmin || isComercial,
          // },
          {
            title: 'Vendedores',
            icon: <Badge fontSize="small" />,
            path: '/usuarios',
            show: isAdmin || isComercial,
          },
          {
            title: 'Vendas',
            icon: <AutoStories fontSize="small" />,
            path: '/vendas',
            show: isAdmin || isComercial,
          },
        ],
      },
    ],
    [
      isAdmin,
      isSeller,
      isComercial,
      isFinancial,
      isMarketing,
      isADMFinancial,
      isCollector,
    ],
  );

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box
          sx={{
            display: {
              lg: 'none',
              xs: 'flex',
            },
            justifyContent: 'center',
            p: 2,
          }}
        >
          <RouterLink to="/">
            <Logo height="40px" />
          </RouterLink>
        </Box>
        <Box sx={{ p: 2 }}>
          <Box
            sx={{
              alignItems: 'center',
              backgroundColor: 'background.default',
              borderRadius: 1,
              display: 'flex',
              overflow: 'hidden',
              p: 2,
            }}
          >
            <Avatar
              // @ts-ignore
              src={user?.name}
              sx={{
                cursor: 'pointer',
                height: 48,
                width: 48,
              }}
            />

            <Box sx={{ ml: 2 }}>
              <Typography color="textPrimary" variant="subtitle2">
                {user?.name}
              </Typography>
              <Typography color="textSecondary" variant="body2">
                {user?.role.label}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          {sections
            .filter(({ show }) => show)
            .map((section) => (
              <NavSection
                key={section.title}
                pathname={location.pathname}
                sx={{
                  '& + &': {
                    mt: 3,
                  },
                }}
                {...section}
              />
            ))}
        </Box>
      </Scrollbar>
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: 'background.paper',
            height: 'calc(100% - 64px) !important',
            top: '64px !Important',
            width: 280,
          },
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: {
          backgroundColor: 'background.paper',
          width: 280,
        },
      }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

export default DashboardSidebar;
