import { ErpProductsInterface } from 'src/modules/erpProducts/types/ErpProductsInterface';
import { convertFormattedMoneyToCents } from 'src/utils/moneyConverter';
import { IOrderProduct } from '../types/OrderProductInterface';
import { IProduct } from '../types/ProductInterface';
import { calculateSubtotal } from './calculateSubtotal';
import { parsePercent, parseValue } from './parseCalc';

interface ICalculateDiscount {
  product: ErpProductsInterface;
  quantity: number;
  offPercent: string | number | null | undefined;
  offValue: string | number | null | undefined;
}

interface IOrderFormProduct
  extends Omit<IOrderProduct, 'total' | 'subtotal' | 'discount'> {}

export function calculateIndividualProductDiscount(product: IOrderFormProduct) {
  const { quantity, off_value } = product;

  const value = parseValue(off_value);
  
  const valueDiscount = value * quantity;

  return valueDiscount;
}

export function calculateDiscountValueOfTotal(product: IOrderFormProduct) {
  const { off_percent } = product;
  const price = Math.round(product.product.prov_valor);

  const percent = parsePercent(off_percent);

  const percentDiscount = (percent / 100) * price;

  return percentDiscount;
}

export function calculateDiscountPercentOfTotal(product: IOrderFormProduct) {
  const { off_value } = product;
  const price = Math.round(product.product.prov_valor);

  const value = parseValue(off_value);

  const percentDiscount = (value / price) * 100;

  return percentDiscount.toFixed(2);
}

export function calculateValueOfOrder(
  totalValue: number,
  discountValue: number,
) {
  const percent = (discountValue / 100) * totalValue;

  return percent;
}

export function calculatePercentOfOrder(
  totalValue: number,
  discountPercent: number,
) {
  const value1 = parseValue(discountPercent);

  const value = (value1 / totalValue) * 100;

  return value.toFixed();
}

export function calculateAllProductsDiscount(products: IOrderProduct[]) {
  return products.reduce(
    (acc, product) => acc + calculateIndividualProductDiscount(product),
    0,
  );
}

export function calculateDiscountOnSubtotal(
  subtotal1: number | undefined,
  off_percent: number | string | undefined,
  off_value: number | string | undefined,
) {
  const percent =
    typeof off_percent === 'number'
      ? off_percent
      : parseInt(`${off_percent || 0}`, 10);

  const value =
    typeof off_value === 'number'
      ? off_value
      : convertFormattedMoneyToCents(off_value || 0);

  const percentDiscount = (percent / 100) * (subtotal1 || 0);
  const valueDiscount = value;

  return valueDiscount;
}
