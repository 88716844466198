import {
  Box,
  Button,
  Card,
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Help } from '@material-ui/icons';
import { useState } from 'react';
import { ICustomer } from 'src/modules/customers/types/CustomerInterface';
import Swal from 'sweetalert2';
import { findScoreByCnpjAssertiva } from '../services/findScoreByCnpjAssertiva';
import { findScoreByCpfAssertiva } from '../services/findScoreByCpfAssertiva';

export interface IScoreVerificationCardProps {
  customerID: string;
  customers: ICustomer[] | undefined;
  setStep: (step: number) => void;
  setScore: (score: number) => void;
  score: number;
}

const ScoreVerificationCard: React.FC<IScoreVerificationCardProps> = ({
  customerID,
  customers,
  setStep,
  setScore,
  score,
}) => {
  const customer1 = customers?.find((customer) => customer.id === customerID);
  const cpfCNPJ = customer1?.cpf_cnpj;

  const [open, handleOpen] = useState(false);
  const [loading, isLoading] = useState(false);

  const handleVerify = async () => {
    isLoading(true);
    if (cpfCNPJ?.length === 11) {
      const response = await findScoreByCpfAssertiva(cpfCNPJ);

      setScore(response.resposta.score.pontos);
      handleOpen(true);
      isLoading(false);
      setStep(2);
    }

    if (cpfCNPJ?.length === 14) {
      const response = await findScoreByCnpjAssertiva(cpfCNPJ);

      setScore(response.resposta.score.pontos);
      handleOpen(true);
      isLoading(false);
      setStep(2);
    }

    if (cpfCNPJ?.length !== 11 && cpfCNPJ?.length !== 14) {
      isLoading(false);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'CPF/CNPJ inválido!',
      });
    }
  };

  return (
    <Card
      style={{
        marginTop: '10px',
      }}
    >
      <Box p={2}>
        <Box
          mt={-1}
          mb={1}
          display="flex"
          flexDirection="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Typography variant="h6">SCORE assertiva</Typography>
          <Tooltip title="Para segurança das nossas vendas, realizamos a verificação das informações dos compradores na Plataforma Assertiva. Caso você identifique algo estranho, clique no botão da verificação para analisar os dados e conferir se o comprador possui identidade correta. Caso contrário, procure seu líder antes de continuar a venda.">
            <IconButton size="small">
              <Help />
            </IconButton>
          </Tooltip>
        </Box>
        {!loading ? (
          <Box
            mt={-1}
            mb={1}
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Button
              variant="outlined"
              color="primary"
              size="small"
              style={{
                marginTop: '10px',
              }}
              onClick={() => handleVerify()}
            >
              Verificar score do cliente na assertiva
            </Button>
            {open && (
              <Box
                mt={1}
                mb={1}
                display="flex"
                flexDirection="column"
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <Typography variant="body1" style={{ marginTop: '10px' }}>
                  Score: {score}
                </Typography>
              </Box>
            )}
          </Box>
        ) : (
          <>
            <Box
              mt={-1}
              mb={1}
              display="flex"
              flexDirection="column"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Button
                variant="contained"
                color="primary"
                size="small"
                style={{
                  marginTop: '10px',
                }}
              >
                Verificando cliente na assertiva ...
              </Button>
            </Box>
            <CircularProgress />
          </>
        )}
      </Box>
    </Card>
  );
};

export default ScoreVerificationCard;
