import { IUser } from '../modules/users/types/UserInterface';
import { login } from '../modules/authentication/services/login';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import firebase from 'firebase/app';
import { useContext } from 'react';
import { AuthContext } from 'src/contexts/AuthContext/AuthContext';
import { AuthTypesEnum } from 'src/contexts/AuthContext/AuthInterface';
import { AUTH_TOKEN } from 'src/services/gqlService';

interface ILoginMethod {
  email: string;
  password: string;
}

interface IUseAuth {
  method: string;
  user: IUser | undefined;
  isLoading: boolean;
  isAuthenticated: boolean;
  isAdmin: boolean;
  isSeller: boolean;
  isComercial: boolean;
  isFinancial: boolean;
  isMarketing: boolean;
  isADMFinancial: boolean;
  isCollector: boolean;
  login: (props: ILoginMethod) => Promise<void>;
  loginWithGoogle: () => Promise<firebase.auth.UserCredential> | undefined;
  loginWithFaceBook: () => Promise<firebase.auth.UserCredential> | undefined;
  loginWithTwitter: () => Promise<firebase.auth.UserCredential> | undefined;
  loginWithMicrosoft: () => Promise<firebase.auth.UserCredential> | undefined;
  register: () => Promise<void>;
  logout: () => Promise<void>;
  resetPassword: (email: string) => Promise<void> | undefined;
  updateProfile: () => Promise<void> | undefined;
}

export default function useAuth(): IUseAuth {
  const token = window.localStorage.getItem(AUTH_TOKEN);
  const history = useNavigate();
  const { firebaseAuth, dispatchAuth, stateAuth } = useContext(AuthContext);

  const providerGoogle = new firebase.auth.GoogleAuthProvider();
  const providerTwitter = new firebase.auth.GoogleAuthProvider();
  const providerFacebook = new firebase.auth.GoogleAuthProvider();
  const providerMicrosoft = new firebase.auth.OAuthProvider('microsoft.com');

  const isAuthenticated = stateAuth.loggedUser !== undefined && !!token;
  const isAdmin = stateAuth.loggedUser?.role?.slug === 'adm';

  return {
    method: 'JWT',
    user: stateAuth.loggedUser,
    isLoading: false,
    isAuthenticated,
    isAdmin: isAdmin,
    isSeller: stateAuth.loggedUser?.role?.slug === 'emp' || isAdmin,
    isComercial: stateAuth.loggedUser?.role?.slug === 'com' || isAdmin,
    isFinancial: stateAuth.loggedUser?.role?.slug === 'fin' || isAdmin,
    isMarketing: stateAuth.loggedUser?.role?.slug === 'mkt' || isAdmin,
    isADMFinancial:
      stateAuth.loggedUser?.role?.slug === 'adm-finance' || isAdmin,
    isCollector: stateAuth.loggedUser?.role?.slug === 'cobr' || isAdmin,
    login: async (props: ILoginMethod) => {
      try {
        const firebaseUser = await firebaseAuth?.signInWithEmailAndPassword(
          props.email,
          props.password,
        );

        const tokenFirebase = await firebaseUser?.user?.getIdToken();
        console.debug(tokenFirebase);

        if (!tokenFirebase) {
          toast.error('Não foi possível fazer login');
          return;
        }

        const { data } = await login(tokenFirebase);

        dispatchAuth({
          type: AuthTypesEnum.AUTHENTICATE_USER,
          loggedUser: data.user,
          token: data.token,
          navigate: history,
        });
      } catch (error: any) {
        let errorMessage = '';
        switch (error.code) {
          case 'auth/internal-error':
            errorMessage = 'Ocorreu um problema, tente novamente.';
            break;

          case 'auth/invalid-credential':
            errorMessage = 'Email ou senha incorretos, tente novamente.';
            break;

          case 'auth/invalid-email	':
            errorMessage = 'O email informado é incorreto, tente novamente';
            break;

          case 'auth/invalid-email-verified	':
            errorMessage = 'O email informado é inválido.';
            break;

          case 'auth/user-not-found':
            errorMessage = 'O usuário não foi encontrado, tente novamente';
            break;

          default:
            errorMessage = 'Não foi possível fazer login, tente novamente';
            break;
        }

        toast.error(errorMessage);
      }
    },
    loginWithGoogle: () => firebaseAuth?.signInWithPopup(providerGoogle),
    loginWithFaceBook: () => firebaseAuth?.signInWithPopup(providerFacebook),
    loginWithTwitter: () => firebaseAuth?.signInWithPopup(providerTwitter),
    loginWithMicrosoft: () => firebaseAuth?.signInWithPopup(providerMicrosoft),
    logout: async () => {
      await dispatchAuth({ type: AuthTypesEnum.LOGOUT_USER });
    },
    resetPassword: (email: string) =>
      firebaseAuth?.sendPasswordResetEmail(email),

    // @ts-ignore
    register: () => {},
    // @ts-ignore
    updateProfile: async (formData: any) => {
      // if (!stateAuth.loggedUser?.uid) return;
      // const response = await updateUser(
      //   stateAuth.loggedUser?.uid,
      //   formData,
      //   cookies[AUTH_TOKEN],
      // );
      // toast.error(errorMessage);
    },
  };
}
