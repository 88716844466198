import { LayoutContextInterface, LayoutTypesEnum } from './LayoutInterface';
import {
  configurePageAction,
  CONFIGURE_PAGE,
} from './actions/configurePageAction';

export type LayoutAction = CONFIGURE_PAGE;

function layoutReducer(
  state: LayoutContextInterface,
  action: LayoutAction,
): LayoutContextInterface {
  switch (action.type) {
    case LayoutTypesEnum.CONFIGURE_PAGE:
      return configurePageAction(state, action);

    default:
      return state;
  }
}

export default layoutReducer;
