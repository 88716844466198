import type { FC } from 'react';

interface LogoAltProps {
  height?: string;
  width?: string;
}

const LogoAlt: FC<LogoAltProps> = ({ height = '40px', width = 'auto' }) => (
  <img
    alt="LogoAlt Napoleão"
    src="/static/logo_alt.png"
    height={height}
    width={width}
  />
);

export default LogoAlt;
