import {
  FormControl,
  Chip,
  Autocomplete,
  TextField,
  createFilterOptions,
  Dialog,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import {
  Control,
  RegisterOptions,
  useController,
  UseControllerProps,
  UseFormSetValue,
} from 'react-hook-form';
import { getHelperMessage } from 'src/components/forms/TextfieldController';
import { ICustomer } from 'src/modules/customers/types/CustomerInterface';
import { ISource } from 'src/modules/customers/types/SourceInterface';
import styled from 'styled-components';
import AutocompleteBusinessCreatableForm from './AutocompleteBusinessCreatebleForm';

interface KeyLabel {
  key: string | number;
  label: string;
}

interface AutocompleteBusinessCreatableInterface
  extends Omit<UseControllerProps<any, any>, 'control' | 'rules'> {
  control: Control<any>;
  rules: Omit<
    RegisterOptions<any, any>,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs'
  >;
  values: KeyLabel[];
  label?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  variant?: 'filled' | 'outlined';
  helperMessage?: string;
  size?: 'small' | 'medium';
  setValue: UseFormSetValue<any>;
  onSuccess: () => void;
  defaultValue?: ICustomer;
  sources: ISource[];
  // onSelected: (value: string | undefined) => void;
}

const CustomFormControl = styled(FormControl)`
  margin-bottom: 0 !important;

  input {
    font-size: 12px;
    height: 23px;
  }
`;

const CREATE_LABEL = 'Criar';

const filter = createFilterOptions<KeyLabel>();

export const AutocompleteBusinessCreatable: React.FC<
  AutocompleteBusinessCreatableInterface
> = ({
  control,
  name,
  rules,
  values,
  defaultValue,
  label,
  helperMessage,
  fullWidth = true,
  variant = 'outlined',
  size = 'medium',
  setValue,
  sources,
  // onSelected,
}) => {
  const [internalValues, setInternalValues] = useState<KeyLabel[]>(values);
  const [internalValue, setInternalValue] = useState<KeyLabel | null>(
    defaultValue
      ? {
          key: String(defaultValue?.id),
          label: String(defaultValue?.name),
        }
      : null,
  );
  const [open, toggleOpen] = useState(false);
  const {
    field: { onChange },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
    // defaultValue,
  });

  const handleClose = () => toggleOpen(false);

  const updateValues = (newValue: KeyLabel) => {
    setInternalValues([newValue, ...internalValues]);
    setInternalValue(newValue);
    // onSuccess();
    setValue('customer', newValue.key);
    onChange(newValue.key);
  };

  useEffect(() => setInternalValues(values), [values]);

  return (
    <CustomFormControl error={!!error} variant={variant} fullWidth={fullWidth}>
      <Autocomplete
        options={internalValues}
        value={internalValue}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        freeSolo
        getOptionLabel={(option) => {
          // e.g value selected with enter, right from the input
          if (typeof option === 'string') {
            return option;
          }
          if (!option.key) {
            return option.label.toString();
          }
          return option.label.toString();
        }}
        fullWidth
        size={size}
        noOptionsText="Nenhum resultado"
        onInputChange={(event, change) => {
          // if (event) {
          //   return;
          // };
          const locatedValue = values.find(
            (_value) => _value.label === change,
          )?.key;
          onChange(locatedValue);
          // @ts-ignore
          // onSelected(locatedValue);
        }}
        onChange={(event, keyLabel) => {
          if (typeof keyLabel === 'string') {
            // timeout to avoid instant validation of the dialog's form.
            setTimeout(() => {
              toggleOpen(true);
              setInternalValue({ key: '', label: keyLabel });
            });
          } else if (
            keyLabel?.key === '' ||
            keyLabel?.label.includes(CREATE_LABEL)
          ) {
            toggleOpen(true);
            setInternalValue({
              key: keyLabel.key,
              label: String(keyLabel.key),
            });
          } else {
            setInternalValue(keyLabel);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params) as KeyLabel[];

          // CRIAR NOVO USUARIO AO ESCREVER UM NOME QUE NÃO EXISTE
          // if (params.inputValue !== '') {
          //   filtered.push({
          //     key: params.inputValue,
          //     label: `${CREATE_LABEL} teste "${params.inputValue}"`,
          //   });
          // }

          return filtered;
        }}
        renderTags={(options) =>
          options.map((option) => (
            <Chip
              variant="outlined"
              size={size}
              key={`chip-${option.key}`}
              label={option.label}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            size={size}
            variant="outlined"
            error={!!error}
            helperText={getHelperMessage(error || helperMessage)}
          />
        )}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <AutocompleteBusinessCreatableForm
          fieldValue={`${internalValue?.key}`}
          handleClose={handleClose}
          updateValues={updateValues}
          sources={sources}
        />
      </Dialog>
    </CustomFormControl>
  );
};
