import {
  Box,
  Checkbox,
  Collapse,
  FormControlLabel,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Help, Warning } from '@material-ui/icons';
import { DatePicker, LocalizationProvider } from '@material-ui/lab';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import { ptBR } from 'date-fns/locale';
import React, { useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { SelectController } from 'src/components/forms/SelectController';
import { TextfieldController } from 'src/components/forms/TextfieldController';
import { IOrderForm } from '../types/OrderFormInterface';
import { IOrder } from '../types/OrderInterface';
import { installments } from '../utils/installments';

interface AdditionalDetailsInterface {
  order: IOrder | undefined;
  methods: UseFormReturn<IOrderForm, any>;
}

const AdditionalDetails: React.FC<AdditionalDetailsInterface> = ({
  order,
  methods,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const { watch, setValue, control } = methods;

  const [showDueDate, setShowDueDate] = React.useState(
    order?.bank_slip_payment === true,
  );

  const [pixPayment, setPixPayment] = React.useState(true);
  const [creditCardPayment, setCreditCardPayment] = React.useState(true);
  const [bankSlipPayment, setBankSlipPayment] = React.useState(true);

  const pix = watch('pix_payment') === true;
  const creditcard = watch('credit_card_payment') === true;
  const bankslip = watch('bank_slip_payment') === true;

  const handleShowDueDate = () => {
    setBankSlipPayment(!bankSlipPayment);
    setValue('bank_slip_payment', bankSlipPayment);
    setShowDueDate(!showDueDate);
  };

  const handlePixPayment = () => {
    setPixPayment(!pixPayment);
    setValue('pix_payment', pixPayment);
  };

  const handleCreditCardPayment = () => {
    setCreditCardPayment(!creditCardPayment);
    setValue('credit_card_payment', creditCardPayment);
  };

  const [showShipping, setShowShipping] = React.useState(false);

  const handleShowShipping = () => {
    setShowShipping(!showShipping);
  };

  return (
    <Box p={2}>
      <Box
        mt={-1}
        mb={1}
        display="flex"
        flexDirection="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Typography variant="h6">Detalhes Adicionais</Typography>
      </Box>

      <FormControlLabel
        control={<Checkbox color="primary" defaultChecked />}
        label="Frete grátis"
        onChange={() => handleShowShipping()}
      />

      <Collapse in={showShipping}>
        <TextfieldController
          type="text"
          label="Preferência de frete"
          name="shipping"
          control={control}
          rules={{ required: false }}
        />
      </Collapse>

      <Box
        mt={2}
        mb={1}
        display="flex"
        flexDirection="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Typography variant="h6">Formas de pagamento</Typography>
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <FormControlLabel
          control={<Checkbox color="primary" />}
          checked={pix}
          label="Pix"
          name="pix_payment"
          onChange={() => handlePixPayment()}
          disabled={creditcard || bankslip}
        />

        <FormControlLabel
          control={<Checkbox color="primary" />}
          checked={creditcard}
          label="Cartão de crédito"
          name="credit_card_payment"
          onChange={() => handleCreditCardPayment()}
          disabled={bankslip || pix}
        />

        <FormControlLabel
          control={<Checkbox color="primary" />}
          checked={bankslip}
          label="Boleto"
          onChange={() => {
            handleShowDueDate();
          }}
          name="bank_slip_payment"
          disabled={pix || creditcard}
        />

        <Collapse in={showDueDate}>
          <LocalizationProvider locale={ptBR} dateAdapter={AdapterDateFns}>
            <Box mt={1}>
              <DatePicker
                views={['year', 'month', 'day']}
                label="Primeira parcela boleto"
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                inputFormat="dd/MM/yyyy"
              // minDate={new Date()}
              // 6 months from today
              // maxDate={new Date(new Date().setMonth(new Date().getMonth() + 6))}
                value={watch('due_date')}
                onChange={(newValue) => {
                // @ts-ignore: type is returning date instead
                setValue('due_date', newValue?.toISOString());
                // setValue('due_date',
                //   // @ts-ignore: DEFAULT_LOCALE is working but typing is not
                //   newValue?.toLocaleDateString('pt-BR', {
                //     year: 'numeric',
                //     month: '2-digit',
                //     day: '2-digit',
                //     timeZone: 'UTC',
                //   }),
                // );
              }}
                renderInput={(params) => <TextField
                  disabled
                  {...params}
                  onKeyDown={(e) => e.preventDefault()}
                  required={false}
                  helperText={null}
                  inputProps={{ ...params.inputProps, readOnly: true }}
                  error={!!methods.formState.errors.due_date}
                  onClick={() => setOpen(true)}
                />}
              />
            </Box>
          </LocalizationProvider>
        </Collapse>
      </Box>

      <Box
        mt={2}
        mb={1}
        display="flex"
        flexDirection="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Typography variant="h6">Detalhes da negociação</Typography>
        <Tooltip title="Aqui você coloca os detalhes">
          <IconButton>
            <Help />
          </IconButton>
        </Tooltip>
      </Box>

      <Box>
        <TextfieldController
          placeholder="Ex: liberado por: "
          name="comment"
          variant="outlined"
          control={control}
          rules={{ required: !!bankslip }}
          multiline
          rows={2}
        />
      </Box>

      {!pix && (
        <Box>
          <SelectController
            name="installments"
            label="Parcelas"
            control={control}
            rules={{ required: true }}
            values={
              installments.installments.map((installment) => ({
                label: `${installment.label}`,
                key: `${installment.value}`,
              })) || []
            }
          />
        </Box>
      )}

    </Box>
  );
};

export default AdditionalDetails;
