import { gql } from "@apollo/client";
import { IUser } from 'src/modules/users/types/UserInterface';
import { mutate, CustomGQLResult } from 'src/services/gqlService';
import { getLoggedUser } from '../../../services/getLoggedUser';

export interface ILogin {
  token: string;
}

export interface ILoginResponse {
  token: string;
  user: IUser;
  firebaseUser?: any;
}

export const login = async (
  firebaseToken: string,
): Promise<CustomGQLResult<ILoginResponse>> => {
  try {
    const response = await mutate<{ login: ILogin }>(
      gql`
        mutation Login($firebaseToken: String!) {
          login (firebaseToken: $firebaseToken) { token }
        }
      `, 
      { firebaseToken }
    );

    window.localStorage.setItem('accessToken', response.data.login.token);

    const userResponse = await getLoggedUser();

    return {
      ...userResponse,
      data : {
        token: response.data.login.token,
        user: userResponse.data,
      }
    };
  } catch (error: any) {
    throw new Error(
      error?.message ? error.message : 'Ocorreu um erro, ao criar o usuário.',
    );
  }
};
