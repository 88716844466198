import { gql } from "@apollo/client";
import { IUser } from "src/modules/users/types/UserInterface";
import { query, CustomGQLResult } from "./gqlService";

export const getLoggedUser = async (): Promise<
  CustomGQLResult<IUser>
> => {
  try {
    const result = await query<{ me: IUser }>(
      gql`
        query {
          me {
            id,
            name,
            email,
            avatar,
            active,
            role {
              id,
              label,
              slug,
            },
          }
        }
      `
    );

    return { data: result.data.me };
  } catch (error: any) {
    throw new Error(
      error?.message ? error.message : 'Ocorreu um erro, tente novamente.',
    );
  }
};
