import React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  MenuItem,
  Select,
} from '@material-ui/core';
import { ComboProductsInterface } from 'src/modules/erpProducts/services/getAllCombos';

interface CombosModalInterface {
  open: boolean;
  handleClose: () => void;
  isLoading?: boolean;
  combos: ComboProductsInterface[];
  setSelectedCombo: (combo: ComboProductsInterface | undefined) => void;
  handleAddProduct?: () => void;
}

const CombosModal: React.FC<CombosModalInterface> = ({
  open,
  handleClose,
  isLoading,
  setSelectedCombo,
  combos,
  handleAddProduct,
}) => (
  <>
    <Dialog
      open={open}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      disableEscapeKeyDown={isLoading}
      sx={{
        '& .MuiDialog-paper': {
          width: '40%',
          maxWidth: 'none',
        },
        m: 1,
      }}
    >
      <DialogTitle
        style={{
          textAlign: 'center',
          color: 'secondary',
        }}
        id="alert-dialog-slide-title"
      >
        Combos disponíveis
      </DialogTitle>
      <Select
        sx={{
          m: 1,
        }}
      >
        {combos.map((combo) => (
          <MenuItem
            value={combo.procs_codsimilar}
            key={combo.procs_codsimilar}
            onClick={() => setSelectedCombo(combo)}
          >
            {combo.procs_codsimilar} : {combo.products.length} produtos
          </MenuItem>
        ))}
      </Select>
      <Box display="flex" justifyContent="flex-end">
        <Button
          color="primary"
          sx={{ m: 2 }}
          variant="contained"
          onClick={async () => {
            if (handleAddProduct) {
              await handleAddProduct();
            }
            handleClose();
          }}
        >
          Selecionar
        </Button>
      </Box>
    </Dialog>
  </>
);

export default CombosModal;
