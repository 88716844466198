import { IOrderProduct } from '../types/OrderProductInterface';

export function calculateSubtotal(selectedFormProducts: IOrderProduct[]) {
  return selectedFormProducts?.reduce((acc, orderProduct) => {
    const { product, quantity } = orderProduct;
    return acc + (product?.prov_valor || 0) * quantity;
  }, 0);
}

export function calculteSubTotalWithDiscount(
  selectedFormProducts: IOrderProduct[],
  discount: number,
) {
  return calculateSubtotal(selectedFormProducts) - discount;
}
