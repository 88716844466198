export function getSimilarity(s1: string, s2: string) {
  const longer = s1;
  const shorter = s2;
  if (longer.length === 0) {
    return 1.0;
  }
  return (
    // @ts-ignore
    (longer.length - editDistance(longer, shorter)) / parseFloat(longer.length)
  );
}

function editDistance(s1: string, s2: string) {
  s1 = s1 !== '' ? s1.toLowerCase() : '';
  s2 = s2 !== '' ? s2.toLowerCase() : '';

  var costs = new Array();
  for (var i = 0; i <= s1.length; i++) {
    var lastValue = i;
    for (var j = 0; j <= s2.length; j++) {
      if (i == 0) costs[j] = j;
      else {
        if (j > 0) {
          var newValue = costs[j - 1];
          if (s1.charAt(i - 1) != s2.charAt(j - 1))
            newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1;
          costs[j - 1] = lastValue;
          lastValue = newValue;
        }
      }
    }
    if (i > 0) costs[s2.length] = lastValue;
  }
  return costs[s2.length];
}
