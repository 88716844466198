import { gql } from '@apollo/client';
import { CustomGQLResult, mutate } from '../../../services/gqlService';
import { IOrderForm } from '../types/OrderFormInterface';
import { IOrder } from '../types/OrderInterface';
import { ICreateOrderProduct } from '../types/OrderProductInterface';
import {
  approvalCreateOrder,
  installmentsValidator,
} from '../utils/approveCreateOrder';
import {
  calculateAllProductsDiscount,
  calculateDiscountOnSubtotal,
  calculateIndividualProductDiscount,
} from '../utils/calculateDiscount';
import { calculateSubtotal } from '../utils/calculateSubtotal';
import { parsePercent, parseValue } from '../utils/parseCalc';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

export interface ICreateOrder
  extends Omit<
    IOrder,
    'id' | 'customer' | 'createdAt' | 'updatedAt' | 'deletedAt'
  > {
  customer: string;
}

interface ICreateOrderRequest extends Omit<ICreateOrder, 'products'> {
  products: ICreateOrderProduct[];
}

export const createOrder = async (
  order: IOrderForm,
): Promise<CustomGQLResult<IOrder>> => {
  try {
    await approvalCreateOrder(order);

    const parsedOffValue = parseValue(order.off_value);
    const parsedPercent = parsePercent(order.off_percent);

    const subtotal = Math.round(calculateSubtotal(order.products));
    const discountProducts = Math.round(
      calculateAllProductsDiscount(order.products),
    );
    const subtotal1 = Math.round(subtotal - discountProducts);
    const discountOrder = Math.round(
      calculateDiscountOnSubtotal(subtotal1, parsedPercent, parsedOffValue),
    );
    const discountTotal = Math.round(discountOrder + discountProducts);
    const total = Math.round(subtotal - discountTotal);

    const isBankSlip = order.bank_slip_payment === true;
    if (isBankSlip) {
      await installmentsValidator(total, order.installments);
    }

    const dueDate = isBankSlip && order.due_date
      ? format(new Date(order.due_date), 'MM-dd-yyyy', { locale: ptBR })
      : null;

    const installments = Number(order.installments);

    const convertedOrder: ICreateOrderRequest = {
      ...order,
      total,
      discount: discountTotal,
      subtotal,
      off_value: parsedOffValue,
      off_percent: parsedPercent,
      due_date: dueDate,
      installments: order?.pix_payment ? 1 : installments,
      // @ts-ignore
      products: order.products.map((product) => {
        const productQuantity = Math.round(parseInt(`${product.quantity}`, 10));
        const productOffValue = parseValue(product.off_value);
        const productOffPercent = parsePercent(product.off_percent);
        const productDiscount = calculateIndividualProductDiscount(product);
        const calculateSubTotal =
          Math.round(productQuantity * product.product.prov_valor) -
          productDiscount;

        return {
          product: product.product.id,
          quantity: productQuantity,
          off_value: productOffValue,
          off_percent: productOffPercent,
          discount: Math.round(productDiscount),
          subtotal: calculateSubTotal,
          total: Math.round(productQuantity * product.product.prov_valor),
          product_slug: product.product.slug_ebook,
        };
      }),
    };

    const result = await mutate<{ createOrder: IOrder }>(
      gql`
        mutation CreateOrder(
          $customer: String!
          $total: Int!
          $discount: Int!
          $subtotal: Int!
          $off_percent: Float
          $erp_request_type: String
          $erp_provider_id: String
          $off_value: Int
          $products: [CreateOrderProductInput!]!
          $due_date: DateTime
          $comment: String
          $pix_payment: Boolean
          $credit_card_payment: Boolean
          $bank_slip_payment: Boolean
          $installments: Int!
        ) {
          createOrder(
            createOrderInput: {
              total: $total
              discount: $discount
              subtotal: $subtotal
              customer: $customer
              off_percent: $off_percent
              off_value: $off_value
              products: $products
              erp_request_type: $erp_request_type
              erp_provider_id: $erp_provider_id
              due_date: $due_date
              comment: $comment
              pix_payment: $pix_payment
              credit_card_payment: $credit_card_payment
              bank_slip_payment: $bank_slip_payment
              installments: $installments
            }
          ) {
            id
          }
        }
      `,
      {
        ...convertedOrder,
      },
    );

    return { data: result.data.createOrder };
  } catch (error: any) {
    throw new Error(
      error?.message ? error.message : 'Ocorreu um erro, tente novamente.',
    );
  }
};
