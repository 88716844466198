import { axiosGET, CustomAxiosResponse } from 'src/services/axiosService';
import { ErpProductsInterface } from '../types/ErpProductsInterface';

export interface ComboProductsInterface {
  procs_codsimilar: string;
  products: ErpProductsInterface[];
}

export const getAllComboProducts = async (): Promise<
  CustomAxiosResponse<ComboProductsInterface[]>
> => {
  try {
    const endpoint = `${process.env.REACT_APP_PUBLIC_BACKEND}/erp-integration/combos`;

    const response = await axiosGET<ComboProductsInterface[]>(endpoint);

    return {
      ...response,
      data: response.data,
      message: 'Pronto!',
    };
  } catch (error: any) {
    throw new Error(
      error?.message ? error.message : 'Ocorreu um erro, tente novamente.',
    );
  }
};
