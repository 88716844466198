export interface IApprovalStatus {
  id: string;
  slug: string;
  label: string;
  created_at?: Date;
  updated_at?: Date;
}

export interface IUpdateOrderInput {
  bank_slip_payment: boolean;
  credit_card_payment: boolean;
  pix_payment: boolean;
  approval_description: string | [];
  shipping_company?: string;
  shipping_cost?: number;
}

export enum ApprovalStatusEnum {
  PENDING_COM = 'pending_com',
  PENDING_FIN = 'pending_fin',
  REJECTED_COM = 'rejected_com',
  REJECTED_FIN = 'rejected_fin',
  APPROVED = 'approved',
  CONFIRMED = 'confirmed',
  CANCELED = 'canceled',
  PAID = 'paid',
  ABANDONED = 'abandoned',
}

export enum ApprovalStatusEnumLabel {
  PENDING_COM = 'Aguardando Cliente',
  PENDING_FIN = 'Aguardando Aprovação Financeiro',
  REJECTED_COM = 'Rejeitado Financeiro',
  REJECTED_FIN = 'Rejeitado Comercial',
  APPROVED = 'Aprovado',
  CONFIRMED = 'Confirmado pelo cliente',
  CANCELED = 'Cancelado',
  PAID = 'Pago pelo cliente',
}

export enum PaymentStatusEnum {
  PENDING = 'pen',
  PAY = 'pay',
  CANCEL = 'can',
}
