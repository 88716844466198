import { set } from 'date-fns';
import React, { createContext, SetStateAction, useEffect, useState } from 'react';

interface DashboardProviderInterface {
  startDate: Date;
  endDate: Date;
  todayDate: Date;
  todayEndDate: Date;
  refetchInterval: number;
  setStartDate: React.Dispatch<SetStateAction<Date>>;
  setEndDate: React.Dispatch<SetStateAction<Date>>;
  counter: number;
  setCounter: React.Dispatch<SetStateAction<number>>;
}

// 1 min
const REFETCH_INTERVAL = 1 * 60 * 1000;

export const START_TIME = { hours: 0, minutes: 0, seconds: 0 };
export const START_DAY = set(new Date(), START_TIME);

export const END_TIME = { hours: 23, minutes: 59, seconds: 59 };
export const END_DAY = set(new Date(), END_TIME);

export const TODAY_DATE = set(new Date(), START_TIME);
export const TODAY_END_DATE = set(new Date(), END_TIME);

export const DashboardContext = createContext<DashboardProviderInterface>({
  refetchInterval: REFETCH_INTERVAL,
  startDate: START_DAY,
  endDate: END_DAY,
  todayDate: TODAY_DATE,
  todayEndDate: TODAY_END_DATE,
  setStartDate: () => console.warn('Ops, the dispatch is not ready'),
  setEndDate: () => console.warn('Ops, the dispatch is not ready'),
  counter: 0,
  setCounter: () => console.warn('Ops, the dispatch is not ready'),
});

const DashboardProvider: React.FC = ({ children }) => {
  const [counter, setCounter] = useState<number>(REFETCH_INTERVAL);
  const [startDate, setStartDate] = useState<Date>(START_DAY);
  const [endDate, setEndDate] = useState<Date>(END_DAY);
  const [todayDate, ,] = useState<Date>(TODAY_DATE);
  const [todayEndDate, ,] = useState<Date>(TODAY_END_DATE);

  useEffect(() => {
    const interval = setInterval(() => {
      setCounter((_counter) => _counter - 1000);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const globals = {
    startDate,
    endDate,
    todayDate,
    todayEndDate,
    setStartDate,
    setEndDate,
    refetchInterval: REFETCH_INTERVAL,
    counter,
    setCounter,
  };

  return <DashboardContext.Provider value={globals}>{children}</DashboardContext.Provider>;
};

export default DashboardProvider;
