import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  Typography,
} from '@material-ui/core';
import { useState } from 'react';
import { ICustomer } from 'src/modules/customers/types/CustomerInterface';
import { SignatureTermsModal } from './checkout/Payment/SignatureTermsModal';
import { SignaturePadModal } from './checkout/Payment/SignaturePadModal';

export interface RenegociationSignatureInterface {
  handleConfirm: () => void;
  isBankSlip?: boolean;
  signatureURL: string;
  setSignatureURL: (newSignatureURL: string) => void;
  renegocation: any;
  loading: boolean;
}

const RenegociationSignature: React.FC<RenegociationSignatureInterface> = ({
  isBankSlip,
  handleConfirm,
  signatureURL,
  setSignatureURL,
  renegocation,
  loading,
}) => {
  const [openSignaturePadModal, setOpenSignaturePadModal] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);

  const handleAcceptTerms = () => {
    if (acceptTerms) {
      setAcceptTerms(false);
    }
  };

  const handleCloseSignaturePadModal = async () => {
    setOpenSignaturePadModal(false);
  };
  const handleOpenSignaturePadModal = async () => {
    setOpenSignaturePadModal(true);
  };
  const handleCloseConfirmSignaturePadModal = async () => {
    setOpenSignaturePadModal(false);
    handleConfirm();
  };

  return (
    <>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          mt: 4,
          mb: 2,
        }}
      >
        <SignaturePadModal
          openModal={openSignaturePadModal}
          handleCloseModal={handleCloseSignaturePadModal}
          handleCloseAndConfirmModal={handleCloseConfirmSignaturePadModal}
          setSignatureURL={setSignatureURL}
          signatureURL={signatureURL}
        />
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Grid item sm={6}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {/* make it horizontal */}
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
            >
              <Checkbox
                checked={acceptTerms}
                onChange={() => setAcceptTerms(!acceptTerms)}
              />
              <Box>
                <Typography>Eu aceito os Termos de renegociação.</Typography>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {loading ? (
              <CircularProgress />
            ) : (
              <Button
                disabled={!acceptTerms}
                type="submit"
                color="primary"
                size="large"
                variant="contained"
                sx={{
                  mb: 3,
                  mt: 3,
                }}
                onClick={handleOpenSignaturePadModal}
              >
                Confirmar renegociação
              </Button>
            )}
          </Box>
        </Grid>
      </Box>
    </>
  );
};

export default RenegociationSignature;
