/* eslint-disable @typescript-eslint/no-unused-vars */
import { LOGOUT_USER, IAuthContext } from '../AuthInterface';

export function logoutUserAction(
  state: IAuthContext,
  action: LOGOUT_USER,
): IAuthContext {
  return {
    ...state,
    loggedUser: undefined,
    firebaseUser: null,
  };
}
