import { ICustomer } from 'src/modules/customers/types/CustomerInterface';
import { ILegalPersonResponse } from '../types/ILegalPersonResponse';
import { IPrivateIndividualResponse } from '../types/IPrivateIndividualResponse';
import { getSimilarity } from './getSimilarity';

export interface ICheckAssertivaResponse {
  name: boolean;
  email: boolean;
  cpfCnpj: boolean;
  phone: boolean;
  address: boolean;
  cadastralSituation: boolean;
}

export const CheckAssertivaCPF = async (
  customer: ICustomer,
  responseCPF: IPrivateIndividualResponse,
): Promise<ICheckAssertivaResponse> => {
  const similarityName = getSimilarity(
    customer.name || '',
    responseCPF.resposta.dadosCadastrais.nome || '',
  );

  const similarityEmail = getSimilarity(
    customer.email || '',
    responseCPF.resposta.emails.length > 0
      ? responseCPF.resposta.emails[0].email
      : '',
  );

  const similarityCpfCnpj = getSimilarity(
    customer.cpf_cnpj || '',
    responseCPF.resposta.dadosCadastrais.cpf || '',
  );

  const similarityPhone = getSimilarity(
    customer.phone || '',
    responseCPF.resposta.telefones.moveis.length > 0
      ? responseCPF.resposta.telefones.moveis[0].numero
      : '',
  );

  const similarityAddress = getSimilarity(
    customer.delivery_address?.zip_code || '',
    responseCPF.resposta.enderecos.length > 0
      ? responseCPF.resposta.enderecos[0].cep
      : '',
  );

  const similarityCadastralSituation = getSimilarity(
    'REGULAR',
    responseCPF.resposta.dadosCadastrais.situacaoCadastral,
  );

  const name = similarityName > 0.5 ? true : false;
  const email = similarityEmail > 0.5 ? true : false;
  const cpfCnpj = similarityCpfCnpj > 0.5 ? true : false;
  const phone = similarityPhone > 0.5 ? true : false;
  const address = similarityAddress > 0.5 ? true : false;
  const cadastralSituation = similarityCadastralSituation > 0.5 ? true : false;

  const checkAssertivaResponse: ICheckAssertivaResponse = {
    name,
    email,
    cpfCnpj,
    phone,
    address,
    cadastralSituation,
  };

  return checkAssertivaResponse;
};

export const CheckAssertivaCNPJ = async (
  customer: ICustomer,
  responseCNPJ: ILegalPersonResponse,
): Promise<ICheckAssertivaResponse> => {
  const similarityName = getSimilarity(
    customer.name,
    responseCNPJ.resposta.dadosCadastrais.razaoSocial,
  );

  const similarityEmail = getSimilarity(
    customer.email || '',
    responseCNPJ.resposta.emails.length > 0
      ? responseCNPJ.resposta.emails[0].email
      : '',
  );

  const similarityCpfCnpj = getSimilarity(
    customer.cpf_cnpj || '',
    responseCNPJ.resposta.dadosCadastrais.cnpj,
  );

  const similarityPhone = getSimilarity(
    customer.phone || '',
    responseCNPJ.resposta.telefones.moveis.length > 0
      ? responseCNPJ.resposta.telefones.moveis[0].numero
      : '',
  );

  const similarityAddress = getSimilarity(
    customer.delivery_address?.zip_code || '',
    responseCNPJ.resposta.enderecos.length > 0
      ? responseCNPJ.resposta.enderecos[0].cep
      : '',
  );

  const similarityCadastralSituation = getSimilarity(
    'REGULAR',
    responseCNPJ.resposta.dadosCadastrais.situacaoCadastral,
  );

  const name = similarityName > 0.5 ? true : false;
  const email = similarityEmail > 0.5 ? true : false;
  const cpfCnpj = similarityCpfCnpj > 0.5 ? true : false;
  const phone = similarityPhone > 0.5 ? true : false;
  const address = similarityAddress > 0.5 ? true : false;
  const cadastralSituation = similarityCadastralSituation > 0.5 ? true : false;

  const checkAssertivaResponse: ICheckAssertivaResponse = {
    name,
    email,
    cpfCnpj,
    phone,
    address,
    cadastralSituation,
  };

  return checkAssertivaResponse;
};
