import { gql } from '@apollo/client';
import { CustomGQLResult, mutate } from 'src/services/gqlService';
import { IOrderForm } from '../types/OrderFormInterface';
import { IOrder } from '../types/OrderInterface';
import { ICreateOrderProduct } from '../types/OrderProductInterface';
import { ApprovalUpdate } from '../utils/approvalUpdate';
import {
  calculateAllProductsDiscount,
  calculateDiscountOnSubtotal,
  calculateIndividualProductDiscount,
} from '../utils/calculateDiscount';
import { calculateSubtotal } from '../utils/calculateSubtotal';
import { parsePercent, parseValue } from '../utils/parseCalc';
import { formatDate } from '../utils/validateBankSlipDate';

export interface IUpdateOrder
  extends Omit<IOrder, 'customer' | 'createdAt' | 'updatedAt' | 'deletedAt'> {
  customer: string;
}

export interface IUpdateOrderRequest extends Omit<IUpdateOrder, 'products'> {
  products: ICreateOrderProduct[];
}

export const updateOrder = async (
  order: IOrderForm,
): Promise<CustomGQLResult<IOrder>> => {
  try {
    await ApprovalUpdate(order);
    const parsedOffValue = parseValue(order.off_value);
    const parsedPercent = parsePercent(order.off_percent);

    const subtotal = Math.round(calculateSubtotal(order.products));
    const discountProducts = Math.round(
      calculateAllProductsDiscount(order.products),
    );
    const subtotal1 = Math.round(subtotal - discountProducts);
    const discountOrder = Math.round(
      calculateDiscountOnSubtotal(subtotal1, parsedPercent, parsedOffValue),
    );
    const discountTotal = Math.round(discountOrder + discountProducts);
    const total = Math.round(subtotal - discountTotal);

    const newDate = formatDate(order.due_date);
    const dueDate = order.due_date ? newDate : null;

    const convertedOrder: IUpdateOrderRequest = {
      ...order,
      total,
      discount: discountTotal,
      subtotal,
      off_value: parsedOffValue,
      off_percent: parsedPercent,
      due_date: dueDate,
      // @ts-ignore
      products: order.products.map((product) => {
        const productQuantity = Math.round(parseInt(`${product.quantity}`, 10));
        const productOffValue = parseValue(product.off_value);
        const productOffPercent = parsePercent(product.off_percent);
        const productDiscount = calculateIndividualProductDiscount(product);

        return {
          product: product.product.id,
          quantity: productQuantity,
          off_value: productOffValue,
          off_percent: productOffPercent,
          discount: Math.round(productDiscount),
          subtotal: Math.round(productQuantity * product.product.prov_valor),
          total: Math.round(productQuantity * product.product.prov_valor),
        };
      }),
    };

    const result = await mutate<{ updateOrder: IOrder }>(
      gql`
        mutation updateOrder(
          $id: String!
          $total: Int!
          $discount: Int!
          $subtotal: Int!
          $off_percent: Float
          $erp_request_type: String
          $erp_provider_id: String
          $off_value: Int
          $products: [CreateOrderProductInput!]!
          $due_date: DateTime
          $comment: String
          $pix_payment: Boolean
          $credit_card_payment: Boolean
          $bank_slip_payment: Boolean
          $renewed_order_times: Int!
        ) {
          updateOrder(
            updateOrderInput: {
              id: $id
              total: $total
              discount: $discount
              subtotal: $subtotal
              off_percent: $off_percent
              off_value: $off_value
              products: $products
              erp_request_type: $erp_request_type
              erp_provider_id: $erp_provider_id
              due_date: $due_date
              comment: $comment
              pix_payment: $pix_payment
              credit_card_payment: $credit_card_payment
              bank_slip_payment: $bank_slip_payment
              renewed_order_times: $renewed_order_times
            }
          ) {
            id
          }
        }
      `,
      {
        ...convertedOrder,
      },
    );

    return { data: result.data.updateOrder };
  } catch (error: any) {
    throw new Error(
      error?.message ? error.message : 'Ocorreu um erro, tente novamente.',
    );
  }
};
