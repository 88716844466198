import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

interface RedirectInterface {
  to: string;
}

const Redirect: React.FC<RedirectInterface> = ({ to }) => {
  const navigate = useNavigate();

  useEffect(() => navigate(to), [navigate, to]);

  return null;
};

export default Redirect;
