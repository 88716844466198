import Swal from 'sweetalert2';
import { IOrderForm } from '../types/OrderFormInterface';
import { ICheckAssertivaResponse } from './checkAssertiva';

export const approvalCreateOrder = async (order: IOrderForm) => {
  if (
    order.pix_payment === false &&
    order.credit_card_payment === false &&
    order.bank_slip_payment === false
  ) {
    throw new Error(
      'É necessário selecionar pelo menos uma forma de pagamento',
    );
  }
  if (order.products.length === 0) {
    throw new Error('É necessário adicionar pelo menos um produto');
  }
  if (
    order.pix_payment === false &&
    (order.installments === null || order.installments === undefined)
  ) {
    throw new Error('É necessário selecionar a quantidade de parcelas');
  }
  if (order.installments > 1 && order.credit_card_payment === true) {
    throw new Error(
      'Para criar uma compra com cartão de crédito, selecione apenas uma parcela',
    );
  }
};

export const approvalAssertiva = async (
  response: ICheckAssertivaResponse | undefined,
) => {
  if (response?.address !== true) {
    Swal.fire({
      title: 'Confira o endereço do cliente',
      text: '',
      icon: 'warning',
      confirmButtonText: 'Ok',
    });
  }
  if (response?.cpfCnpj !== true) {
    Swal.fire({
      title: 'Confira o CPF/CNPJ do cliente',
      text: '',
      icon: 'warning',
      confirmButtonText: 'Ok',
    });
  }
  if (response?.name !== true) {
    Swal.fire({
      title: 'Confira o nome do cliente',
      text: '',
      icon: 'warning',
      confirmButtonText: 'Ok',
    });
  }
  if (response?.phone !== true) {
    Swal.fire({
      title: 'Confira o telefone do cliente',
      text: '',
      icon: 'warning',
      confirmButtonText: 'Ok',
    });
  } else {
    return false;
  }
};

export async function installmentsValidator(
  total: number,
  installments: number,
) {
  if (total <= 6000 && installments > 1) {
    throw new Error('O valor mínimo da parcela é de R$ 60,00');
  }
  if (total <= 12000 && installments > 2) {
    throw new Error('O valor mínimo da parcela é de R$ 60,00');
  }
  if (total <= 18000 && installments > 3) {
    throw new Error('O valor mínimo da parcela é de R$ 60,00');
  }
  if (total <= 24000 && installments > 4) {
    throw new Error('O valor mínimo da parcela é de R$ 60,00');
  }
  if (total <= 30000 && installments > 5) {
    throw new Error('O valor mínimo da parcela é de R$ 60,00');
  }
  if (total <= 36000 && installments > 6) {
    throw new Error('O valor mínimo da parcela é de R$ 60,00');
  }
  if (total <= 42000 && installments > 7) {
    throw new Error('O valor mínimo da parcela é de R$ 60,00');
  }
  if (total <= 48000 && installments > 8) {
    throw new Error('O valor mínimo da parcela é de R$ 60,00');
  }
  if (total <= 54000 && installments > 9) {
    throw new Error('O valor mínimo da parcela é de R$ 60,00');
  }
  if (total <= 60000 && installments > 10) {
    throw new Error('O valor mínimo da parcela é de R$ 60,00');
  }
  if (total <= 66000 && installments > 11) {
    throw new Error('O valor mínimo da parcela é de R$ 60,00');
  }
  if (total <= 72000 && installments > 12) {
    throw new Error('O valor mínimo da parcela é de R$ 60,00');
  }
}

export async function assertivaApproval(
  response: ICheckAssertivaResponse | undefined,
  bankSlip: boolean,
  score: number,
) {
  if (
    score === 0 &&
    bankSlip === true &&
    response?.address === true &&
    response?.cpfCnpj === true &&
    response?.name === true &&
    response?.email === true
  ) {
    return true;
  }
  if (
    (bankSlip === true && score === 0 && response?.cpfCnpj === false) ||
    (response?.address === false &&
      response?.name === false &&
      response?.email === false)
  ) {
    Swal.fire({
      title: 'Confira os dados do cliente',
      text: 'Dados foram verificados na Assertiva e estão com alguma incoerência, somente cartão de crédito liberado',
      icon: 'warning',
      confirmButtonText: 'Ok',
    });
    return false;
  }
  if (
    score === 0 &&
    bankSlip === true &&
    response?.cpfCnpj === true &&
    (response?.address === true ||
      response?.phone === true ||
      response?.email === true)
  ) {
    return true;
  }

  // if (score < 400 && bankSlip === true) {
  //   Swal.fire({
  //     title:
  //       'O cliente não possui score suficiente para realizar a compra nessa forma de pagamento',
  //     text: '',
  //     icon: 'warning',
  //     confirmButtonText: 'Ok',
  //   });
  //   return false;
  // }
  // if (bankSlip === true && score > 400) {
  //   return true;
  // }

  return true;
}
