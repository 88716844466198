import { Box, Container, Typography, Button } from '@material-ui/core';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';

interface EmptyPlaceholderInterface {
  title: string;
  subtitle: string;
  path?: string;
  buttonTitle?: string;
  onClick?: () => void;
  img?: string;
}

const EmptyPlaceholder: React.FC<EmptyPlaceholderInterface> = ({
  title,
  subtitle,
  path,
  buttonTitle,
  img,
  onClick,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Container>
      <Box
        display="flex"
        minHeight="20em"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}
      >
        <Box mb={2}>
          <img
            height="100px"
            width="auto"
            src={img || `/static/error/error500_${theme.palette.mode}.svg`}
            alt=""
          />
        </Box>

        <Typography variant="h3" gutterBottom>
          {title}
        </Typography>

        <Box mb={5}>
          <Typography sx={{ color: 'text.secondary' }}>{subtitle}</Typography>
        </Box>

        {/* <Box
            component={motion.img}
            variants={varBounceIn}
            alt="404"
            src="/static/illustrations/illustration_register.svg"
            sx={{ width: '100%', maxHeight: 240, my: { xs: 5, sm: 10 } }}
          /> */}

        {buttonTitle && (
          <Button
            size="large"
            variant="contained"
            onClick={() => {
              if (onClick) onClick();
              if (path) navigate(path);
            }}
          >
            {buttonTitle}
          </Button>
        )}
      </Box>
    </Container>
  );
};

export default EmptyPlaceholder;
