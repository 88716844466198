import type { FC } from 'react';
import { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Button,
  Container,
  List,
  ListItem,
  ListItemIcon,
  Typography,
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import gtm from '../../../lib/gtm';

export const PrivacyPolicy: FC = () => {
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  return (
    <>
      <Helmet>
        <title>Aviso de Privacidade | Napoleao</title>
      </Helmet>
      <Box
        sx={{
          alignItems: 'center',
          backgroundColor: 'background.paper',
          display: 'flex',
          minHeight: '100%',
          px: 3,
          py: '80px',
        }}
      >
        <Container maxWidth="lg">
          <Typography
            align="center"
            color="textPrimary"
            variant={mobileDevice ? 'h4' : 'h1'}
          >
            Aviso de privacidade
          </Typography>
          <Box sx={{ my: 2 }}>
            <Typography variant="body1" gutterBottom>
              A Editora Napoleão Ltda, através de seus sócios proprietários,
              dentro dos valores, da missão e da visão* da empresa, das leis
              nacionais de defesa do consumidor, sob orientação de sua assessora
              jurídica, considerando:
            </Typography>
            <Typography variant="body1" gutterBottom>
              Links embutidos (*) Tem a Missão de Oferecer aos clientes
              atualizações constantes, antecipando-se às suas necessidades,
              garantindo qualidade e diferenciais no atendimento com auxílio de
              profissionais altamente comprometidos e qualificados.
            </Typography>
            <List>
              <ListItem>
                <Typography variant="body1" gutterBottom>
                  Tem a Visão de Ser referência na área da saúde, com uma
                  operação de vendas direta ao consumidor, priorizando o
                  atendimento diferenciado, com qualidade, agilidade e respeito.
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="body1" gutterBottom>
                  Tem o Propósito de Propagar conteúdos relevantes para uma
                  modificação profunda e positiva na vida das pessoas.
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="body1" gutterBottom>
                  A Editora Napoleão tem o compromisso com a privacidade e a
                  segurança de seus clientes durante todo o processo de
                  navegação e compra pelo site.
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="body1" gutterBottom>
                  Os dados cadastrais dos clientes não são vendidos, trocados ou
                  divulgados para terceiros, exceto quando essas informações são
                  necessárias para o processo de entrega.
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="body1" gutterBottom>
                  Seus dados pessoais são peça fundamental para que seu pedido
                  chegue em segurança, na sua casa, de acordo com nosso prazo de
                  entrega.
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="body1" gutterBottom>
                  Para que estes dados permaneçam intactos, nós desaconselhamos
                  expressamente a divulgação de sua senha a terceiros, mesmo a
                  amigos e parentes. Em atendimento ao Código de Defesa do
                  Consumidor (Lei 8.078/1990*), ao Marco Civil da Internet (Lei
                  nº 12.965/2014*), seu decreto regulamentador (Decreto nº
                  8.771/2016*), à LGPD - Lei Geral de Proteção de Dados Pessoais
                  (Lei 13.709/2018*). Em atendimento aos valores preconizados na
                  atuação profissional a credibilidade de décadas de atuação no
                  mercado editorial sem máculas, cria o:
                </Typography>
              </ListItem>
            </List>
            <Typography variant="body1" gutterBottom>
              AVISO DE PRIVACIDADE E DE PROTEÇÃO DE DADOS PESSOAIS
            </Typography>
            <List>
              <ListItem>
                Em caso de dúvidas adicionais ou requisições, por favor, entre
                em contato com a Encarregada de Proteção de Dados (DPO) externa,
                Rosalia Toledo Veiga Ometto, através do e-mail:
                dpo@editoranapoleao.com.br
              </ListItem>
            </List>
            <Typography variant="h4" sx={{ mt: 3 }}>
              QUADRO RESUMO
            </Typography>
            <Typography variant="body1" gutterBottom>
              Agente de tratamento | Editora Napoleão Ltda. Papel no tratamento
              de dados pessoais| Predominantemente CONTROLADORA, eventualmente
              OPERADORA. Natureza dos dados pessoais tratados | Dados pessoais |
              diretamente dos TITULARES Por exemplo: Colaboradores, Clientes
              Pessoas Físicas, Parceiros de Negócios
            </Typography>
            <Typography variant="h4" sx={{ mt: 3 }}>
              Principais finalidades do tratamento dados pessoais na Editora
              Napoleão
            </Typography>
            <Typography variant="body1" gutterBottom>
              Utilizar dados pessoais dos TITULARES para atender a uma relação
              contratual estabelecida entre as partes para venda de publicações
              especializadas no setor odontológico e da saúde em geral Tais
              como: contrato de venda e compra de produtos comercializados com
              seus clientes, e todos os demais decorrentes do seu escopo de
              negócio Compartilhamento com os parceiros | os dados pessoais
              minimamente necessários para cumprimento de obrigação legal ou
              regulatória, exercício regular de direito, dentre outros com menor
              incidência. Por exemplo: Governo (tributação), Correios e
              Transportadoras (entrega dos produtos adquiridos), Sistema
              Financeiro (boletos), Operadoras de Cartão de Crédito (efetivação
              da compra), Contabilidade (obrigações legais) Destaca-se que não
              há comercialização da base de dados pessoais da Editora Napoleão
              sob qualquer circunstância.
            </Typography>
            <Typography variant="h4" sx={{ mt: 3 }}>
              Para que são coletados dados pessoais na Editora Napoleão?
            </Typography>
            <Typography variant="body1" gutterBottom>
              <List>
                <ListItem>
                  <Typography variant="body1" gutterBottom>
                    Utilizar dados pessoais para entrar, responder ou manter
                    contato antes, durante ou após uma relação contratual com o
                    TITULAR, na qualidade de CONTROLADOR, visando o cumprimento
                    do contrato (em geral contrato de venda e compra de produtos
                    comercializados para pessoas físicas, contrato de trabalho).
                  </Typography>
                </ListItem>
                <ListItem>
                  Base legal | Execução de contrato com o TITULAR e/ou
                  cumprimento de obrigação legal ou regulatória.
                </ListItem>
                <ListItem>
                  Eventualmente utilizar dados pessoais para fins de divulgação
                  de conteúdo ou de participação e divulgação em evento e de
                  ações, ligados aos serviços da Editora Napoleão. Base legal |
                  Consentimento do TITULAR.
                </ListItem>
              </List>
            </Typography>
            <Typography variant="h4" sx={{ mt: 3 }}>
              Como a Editora Napoleão realiza a proteção de dados pessoais e seu
              armazenamento ?
            </Typography>

            <Typography variant="body1" gutterBottom>
              Eventualmente utilizar dados pessoais para fins de divulgação de
              conteúdo ou de participação e divulgação em evento e de ações,
              ligados aos serviços da Editora Napoleão.
            </Typography>
            <Typography variant="body1" gutterBottom>
              Medidas de segurança, técnicas e administrativas adequadas são
              adotadas pela Editora Napoleão, sendo que, como padrão não tem
              tratamento de documentos físicos originais, apenas cópias e de
              forma majoritária digitais ou digitalizados, com acesso
              absolutamente restrito de pessoas e de colaboradores.
            </Typography>
            <Typography variant="body1" gutterBottom>
              Todos os documentos digitais e todos os dados pessoais digitais da
              Editora Napoleão, são armazenadas em servidores próprios.
            </Typography>
            <Typography variant="body1" gutterBottom>
              Não há tratamento automatizados de dados pessoais na Editora
              Napoleão
            </Typography>

            <Typography variant="h4" sx={{ mt: 3 }}>
              Quais os direitos dos titulares de dados pessoais junto à Editora
              Napoleão?
            </Typography>
            <List>
              <ListItem>
                Seus direitos | Confirmação da existência de tratamento, acesso,
                correção, portabilidade, exclusão (salvo se houver uma base
                legal que fundamente manter os dados pessoais), entre outros,
                podendo requerer maiores esclarecimentos ou solicitações através
                do e-mail: dpo@editoranapoleao.com.br
              </ListItem>
            </List>
            <Typography variant="h4" sx={{ mt: 3 }}>
              Definições
            </Typography>
            <Typography variant="body1" gutterBottom>
              Dado pessoal | Qualquer informação relacionada a pessoa natural,
              direta ou indiretamente, identificada ou identificável (por
              exemplo, nome, CPF, nº IP computador ou celular) Dado pessoal
              sensível | Categoria especial de dados pessoais referentes a
              origem racial ou étnica, convicção religiosa, opinião política,
              filiação a sindicato ou a organização de carácter religioso,
              filosófico ou político, referentes à saúde ou à vida sexual, dados
              genéticos ou biométricos relativos à pessoa natural. Esses dados
              que não são utilizados com os clientes, apenas alguns desses dados
              para colaboradores em virtude de obrigações legais ou
              regulatórias. Titular | pessoa natural a quem se referem os dados
              pessoais, tais como antigos, presentes ou potenciais clientes que
              compartilham dados com a Editora Napoleão (cliente pessoas
              físicas, prestadores de serviços, terceiros), colaboradores,
              contratados, parceiros comerciais e terceiros. Tratamento | toda e
              qualquer operação ou conjunto de operações efetuadas sobre dados
              pessoais ou sobre conjuntos de dados pessoais, por meios
              automatizados ou não automatizados, tais como a coleta, o
              registro, a organização, a estruturação, a conservação, a
              adaptação ou alteração, a recuperação, a consulta, a utilização, a
              divulgação por transmissão, difusão ou qualquer outra forma de
              disponibilização, a comparação ou interconexão, a limitação, a
              eliminação ou a destruição (LGPD, art. 5º, X) Base legal | É o que
              permite o agente tratar os dados pessoais, o fundamento legal, a
              principal é o Consentimento do TITULAR, que pode ser revogado a
              qualquer tempo, desde que não haja outras exceções legais que
              permitem o CONTROLADOR continuar tratando dos dados pessoais,
              mesmo se o Consentimento do TITULAR. É definida pelo CONTROLADOR
              de acordo com as características do negócio, do produto ou do
              serviço prestado. Encarregado | é o mediador, pessoa indicada pelo
              controlador e operador para atuar como canal de comunicação entre
              o controlador, os titulares dos dados pessoais e a Autoridade
              Nacional de Proteção de Dados – ANPD. (LGPD, arts. 5º, VIII e 41)
              Agentes de Tratamento | são os que tem a custódia dos dados
              pessoais e devem fazer todo o tratamento de acordo com as
              determinações legais e de acordo com os princípios da LGPD,
              sobretudo, com boa-fé e com transparência. São os CONTROLADORES e
              os OPERADORES. Controlador | agente de tratamento, parte que
              determina as finalidades e os meios de tratamento de dados
              pessoais. No caso do presente, o CONTROLADOR é a Editora Napoleão
              (LGPD, art. 5º, VI e IX), tais como dados pessoais cadastrais dos
              clientes e dados pessoais dos colaboradores Operador | agente de
              tratamento, parte que trata dados pessoais de acordo com as
              instruções do CONTROLADOR. No caso do presente, o OPERADOR são os
              parceiros de negócio (instituição financeira para gerar boletos,
              advogado, DPO externo, contador, entre outros de estrita
              necessidade). (LGPD, art. 5º, VII e IX) Anonimização | processo
              por meio do qual o dado perde a possibilidade de associação,
              direta ou indireta, a um indivíduo, considerados os meios técnicos
              razoáveis e disponíveis no momento do tratamento.
            </Typography>
            <Typography variant="h4" sx={{ mt: 3 }}>
              A Editora Napoleão e os Cookies
            </Typography>
            <Typography variant="body1" gutterBottom>
              Cookies são arquivos ou informações que podem ser armazenadas em
              seus dispositivos quando você visita os websites ou utiliza os
              serviços on-line, como o site e redes sociais da Editora Napoleão,
              inclusive no uso restrito, na área do cliente, apenas utiliza
              cookies para facilitar o uso e melhor adaptar suas páginas aos
              interesses e necessidades dos TITULARES, bem como para consolidar
              a compra dos produtos e também compilar informações sobre a
              utilização de dos sites das redes sociais, auxiliando a melhorar
              suas estruturas e seus conteúdos. Os cookies também podem ser
              utilizados para acelerar suas atividades.
            </Typography>

            <Typography variant="body1" gutterBottom>
              Alguns tipos de cookies, quais são eles e o que fazem?
            </Typography>
            <Typography variant="body1" gutterBottom>
              Cookies Funcionais | Esses cookies são essenciais para que as
              páginas da Editora Napoleão carreguem corretamente e permitem que
              cliente navegue em nossos sites e faça uso de todas as
              funcionalidades.
            </Typography>
            <Typography variant="body1" gutterBottom>
              Cookies de Marketing | Esses cookies nos ajudam a entender como
              clientes/visitantes interagem com as páginas da Editora Napoleão,
              fornecendo informações sobre as áreas visitadas, o tempo de visita
              ao site e quaisquer problemas encontrados, como mensagens de erro,
              entretanto não identificam dados pessoais, e não são armazenados
              para outros fins a não ser melhor navegação e experiência no site
              ou nas redes sociais.
            </Typography>
            <Typography variant="body1" gutterBottom>
              Cookies de sessão | São Cookies de uso temporário, que são
              excluídos no momento em que cliente fecha o seu navegador. Quando
              cliente reinicia o seu navegador e volta para o site que criou o
              cookie, esse site trata cliente como novo visitante.
            </Typography>
            <Typography variant="body1" gutterBottom>
              Cookies persistentes | São aqueles cookies que permanecem no seu
              navegador até cliente deletá-los manualmente ou até o seu
              navegador deletá-los de acordo com o período de duração
              estabelecido pelo cookie. Esses Cookies reconhecerão seu retorno
              como visitante do site.
            </Typography>
            <Typography variant="body1" gutterBottom>
              Cookies necessários | São Cookies estritamente necessários para a
              operação de um site ou serviço compatível da Editora Napoleão.
              Eles permitem que cliente/visitante navegue pelo site e use nossos
              recursos.
            </Typography>
            <Typography variant="body1" gutterBottom>
              Cookies que nos mandam informações sobre usuário | Nós colocamos
              esse tipo de Cookies em um site ou serviço compatível da Editora
              Napoleão e este tipo de Cookie só podem ser lidos por nossos sites
              e serviços compatíveis.
            </Typography>
            <Typography variant="body1" gutterBottom>
              Cookies em propagandas da Editora Napoleão | Colocamos Cookies em
              propagandas e anúncios que são exibidos em sites e serviços
              compatíveis de terceiros. Obtemos informações através desses
              Cookies quando usuário clica ou interage com a propaganda ou
              anúncio. Neste caso, a Editora Napoleão está colocando um cookie
              “de terceiro”. Nós podermos usar esses dados obtidos por Cookies
              de terceiros para mandar-lhe outras propagandas que acreditamos
              ser relevantes ou de seu interesse com base no seu comportamento
              anterior.
            </Typography>

            <Typography variant="body1" gutterBottom>
              A qualquer momento, o TITULAR poderá apagar os cookies das páginas
              da Editora Napoleão utilizando as configurações de seu navegador
              de preferência. Para mais informações sobre como proceder em
              relação à gestão dos cookies nos navegadores:
            </Typography>
            <Typography variant="body1" gutterBottom>
              Mozilla Firefox:
              https://support.mozilla.org/pt-BR/kb/ative-e-desative-os-cookies-que-os-sites-usam
              Google Chrome:
              https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&hl=pt-BR
              Safari: https://support.apple.com/pt-br/guide/safari/sfri11471/mac
              Internet Explorer:
              https://support.microsoft.com/pt-br/help/17442/windows-internet-explorer-delete-manage-cookies
            </Typography>
            <Typography variant="h4" sx={{ mt: 3 }}>
              Com Quem a Editora Napoleão compartilha dados pessoais?
            </Typography>
            <Typography variant="body1" gutterBottom>
              A Editora Napoleão não comercializa dados pessoais com terceiros.
              Entretanto, poderá compartilhar dados pessoais com OPERADORES de
              dados pessoais que seguirão as finalidades específicas para
              tratamento dos dados pessoais, como por exemplo: Com Empresas
              Parceiras e fornecedores, no desenvolvimento dos Serviços voltados
              ao TITULAR ou à Editora Napoleão Com autoridades, entidades
              governamentais ou outros terceiros, para a proteção dos interesses
              da Editora Napoleão em qualquer tipo de conflito, incluindo ações
              judiciais e processos administrativos No caso de transações e
              alterações societárias envolvendo a Editora Napoleão, hipótese em
              que a transferência das informações será necessária para a
              continuidade dos serviços Mediante ordem judicial ou pelo
              requerimento de autoridades administrativas que detenham
              competência legal para sua requisição. Adicionalmente, é possível
              que algumas das transferências indicadas acima, em especial pelo
              armazenamento em nuvem possam ocorrer fora do Brasil, entre em
              contato com a Encarregada – DPO, Rosalia Toledo Veiga Ometto,
              dpo@editoranapoleao.com.br, dentro das hipóteses autorizadas por
              lei. Ainda que a Editora Napoleão adote a melhor estrutura
              possível para preservar a privacidade e proteger os dados pessoais
              dos TITULARES, não possibilidade técnica de oferecer segurança
              absoluta e em decorrência disso não há possibilidade de garantia
              integral de comprometimento de dados pessoais. De tal modo, a
              Editora Napoleão orienta aos TITULARES de dados pessoais que se
              empenhem em adotar medidas pessoais de segurança e de privacidade,
              com aprendizado de atitudes de segurança, com aprendizado e
              letramento digital contínuo
            </Typography>
            <Typography variant="h4" sx={{ mt: 3 }}>
              Ciclo de vida das Informações Coletadas
            </Typography>
            <Typography variant="body1" gutterBottom>
              Os dados pessoais tratados pela Editora Napoleão serão
              automaticamente eliminados quando deixarem de ser úteis para os
              fins para os quais foram coletados, ou quando o TITULAR solicitar
              sua eliminação, salvo quando houver base legal para a Editora
              Napoleão para manter tais dados pessoais sob sua custódia. As
              requisições devem ser enviadas para a Encarregada de Proteção de
              Dados, Rosalia Toledo Veiga Ometto, através do endereço de e-mail:
              dpo@editoranapoleao.com.br, em que serão respondidas pela Editora
              Napoleão.
            </Typography>
          </Box>
        </Container>
      </Box>
    </>
  );
};
