import type { FC } from 'react';

interface LogoProps {
  height?: string;
  width?: string;
  maxWidth?: string;
  paddingLeft?: string;
  paddingRight?: string;
}

const Logo: FC<LogoProps> = ({
  height = 'auto',
  width = 'auto',
  maxWidth = '100%',
  paddingLeft = '0',
  paddingRight = '0',
}) => (
  <img
    alt="Logo Napoleao"
    src="/static/logo.png"
    height={height}
    width={width}
    style={{ maxWidth, paddingLeft, paddingRight }}
  />
);

export default Logo;
