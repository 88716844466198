import { Suspense, lazy, LazyExoticComponent } from 'react';
// @ts-ignore
import type { PartialRouteObject } from 'react-router';

import AuthGuard from './components/AuthGuard';
import GuestGuard from './components/GuestGuard';
import DashboardLayout from './components/dashboard/DashboardLayout';
import LoadingScreen from './components/LoadingScreen';
import MainLayout from './components/MainLayout';
import Home from './icons/Home';
import Redirect from './utils/Redirect';
import Users from './modules/users';
import Customers from './modules/customers';
import Orders from './modules/orders';
import ErpProducts from './modules/erpProducts';
import { OrderMinimal } from './modules/orders/pages/OrderMinimal';
import MinimalLayout from './components/dashboard/MinimalLayout';
import Financial from './modules/financial';
import DashboardProvider from './modules/dashboard/contexts/DashboardContext';
import CouncilsRootPage from './modules/councils/CouncilsRootPage';
import { PrivacyPolicy } from './modules/public/pages/PrivacyPolicy';
import Renegociation from './modules/public/pages/Renegociation';
import { RenegociationCarta } from './modules/public/pages/RenegociationCarta';
import RawLayout from './components/RawLayout';

const Loadable =
  (Component: LazyExoticComponent<React.FC<{}>>) => (props: any) =>
    (
      <Suspense fallback={<LoadingScreen />}>
        <Component {...props} />
      </Suspense>
    );

// Authentication

const Login = Loadable(
  lazy(() => import('./modules/authentication/pages/Login')),
);
const PasswordRecovery = Loadable(
  lazy(() => import('./modules/authentication/pages/PasswordRecovery')),
);
const PasswordReset = Loadable(
  lazy(() => import('./modules/authentication/pages/PasswordReset')),
);
const Register = Loadable(
  lazy(() => import('./modules/authentication/pages/Register')),
);
const VerifyCode = Loadable(
  lazy(() => import('./modules/authentication/pages/VerifyCode')),
);

// Dashboard

const Account = Loadable(
  lazy(() => import('./modules/dashboard/pages/Account')),
);
const Overview = Loadable(
  lazy(() => import('./modules/dashboard/pages/Overview')),
);

// User

const UserDetails = Loadable(
  lazy(() => import('./modules/users/pages/UserDetails')),
);
const UserEdit = Loadable(lazy(() => import('./modules/users/pages/UserEdit')));
const UserCreate = Loadable(
  lazy(() => import('./modules/users/pages/UserCreate')),
);
const UserList = Loadable(lazy(() => import('./modules/users/pages/UserList')));

// Customer

const CustomerDetails = Loadable(
  lazy(() => import('./modules/customers/pages/CustomerDetails')),
);
const CustomerEdit = Loadable(
  lazy(() => import('./modules/customers/pages/CustomerEdit')),
);
const CustomerCreate = Loadable(
  lazy(() => import('./modules/customers/pages/CustomerCreate')),
);
const CustomerList = Loadable(
  lazy(() => import('./modules/customers/pages/CustomerList')),
);

// Order

const OrderDetails = Loadable(
  lazy(() => import('./modules/orders/pages/OrderDetails')),
);
const OrderCreate = Loadable(
  lazy(() => import('./modules/orders/pages/OrderCreate')),
);
const OrderList = Loadable(
  lazy(() => import('./modules/orders/pages/OrderList')),
);
const OrderListTable = Loadable(
  lazy(() => import('./modules/orders/components/OrderListTable')),
);

const OrderListAdmin = Loadable(
  lazy(() => import('./modules/orders/pages/OrderApprovalList')),
);
const OrderEdit = Loadable(
  lazy(() => import('./modules/orders/pages/OrderEdit')),
);

const CouncilDetails = Loadable(
  lazy(() => import('./modules/councils/CouncilDetails')),
);
const CouncilCreate = Loadable(
  lazy(() => import('./modules/councils/CouncilCreate')),
);
const CouncilList = Loadable(
  lazy(() => import('./modules/councils/CouncilList')),
);
const CouncilEdit = Loadable(
  lazy(() => import('./modules/councils/CouncilEdit')),
);

// Error

const AuthorizationRequired = Loadable(
  lazy(() => import('./modules/public/pages/AuthorizationRequired')),
);
const NotFound = Loadable(
  lazy(() => import('./modules/public/pages/NotFound')),
);
const ServerError = Loadable(
  lazy(() => import('./modules/public/pages/ServerError')),
);

// Products

const ProductList = Loadable(
  lazy(() => import('./modules/erpProducts/ErpProductsList')),
);
const ProductEdit = Loadable(
  lazy(() => import('./modules/erpProducts/ErpProductsEdit')),
);

// Checkout

const Checkout = Loadable(
  lazy(() => import('./modules/public/pages/Checkout')),
);

const CheckoutLogin = Loadable(
  lazy(() => import('./modules/public/pages/CheckoutLogin')),
);

const CheckoutBillingChoice = Loadable(
  lazy(() => import('./modules/public/pages/CheckoutBillingChoice')),
);

const CreditCard = Loadable(
  lazy(() => import('./modules/public/pages/CreditCard')),
);

const Pix = Loadable(lazy(() => import('./modules/public/pages/Pix')));

const BankSlip = Loadable(
  lazy(() => import('./modules/public/pages/BankSlip')),
);

const ConfirmPayment = Loadable(
  lazy(() => import('./modules/public/pages/ConfirmPayment')),
);

const ConfirmBankSlip = Loadable(
  lazy(() => import('./modules/public/pages/ConfirmBankSlip')),
);

// Email Form
const EmailForm = Loadable(
  lazy(() => import('./modules/public/pages/SendEmail')),
);

// Gestão
const FinancialList = Loadable(
  lazy(() => import('./modules/financial/pages/FinancialList')),
);

const PaymentsList = Loadable(
  lazy(() => import('./modules/financial/pages/PaymentsList')),
);

const AllFinancialList = Loadable(
  lazy(() => import('./modules/financial/pages/AllFinancialList')),
);

// Routes

const routes: PartialRouteObject[] = [
  {
    path: '/',
    element: (
      <AuthGuard>
        <DashboardProvider>
          <DashboardLayout />
        </DashboardProvider>
      </AuthGuard>
    ),
    children: [
      { path: '', element: <Overview /> },
      { path: 'account', element: <Account /> },
      // Users
      {
        path: '/usuarios',
        element: <Users />,
        children: [
          { path: '', element: <UserList /> },
          { path: 'novo', element: <UserCreate /> },
          { path: ':userId', element: <UserDetails /> },
          { path: ':userId/editar', element: <UserEdit /> },
        ],
      },
      {
        path: '/gestao',
        element: <Financial />,
        children: [
          { path: 'dashboard-financeiro', element: <FinancialList /> },
          { path: 'all', element: <AllFinancialList /> },
          { path: 'pagamentos', element: <PaymentsList /> },
        ],
      },
      {
        path: '/marketing',
        children: [{ path: '', element: <EmailForm /> }],
      },
      {
        path: '/vendas',
        element: <OrderListAdmin />,
      },
      // Customers
      {
        path: 'clientes',
        element: <Customers />,
        children: [
          { path: '', element: <CustomerList /> },
          { path: 'novo', element: <CustomerCreate /> },
          { path: ':customerId', element: <CustomerDetails /> },
          { path: ':customerId/editar', element: <CustomerEdit /> },
        ],
      },
      // Orders
      {
        path: 'orcamentos',
        element: <Orders />,
        children: [
          { path: '', element: <OrderList /> },
          { path: 'novo', element: <OrderCreate /> },
          { path: ':orderId', element: <OrderDetails /> },
          { path: ':orderId/editar', element: <OrderEdit /> },
        ],
      },
      // Approvals
      {
        path: 'aprovacoes',
        element: <Orders />,
        children: [
          {
            path: 'financeiro',
            element: <OrderListTable isFinancialApproval />,
          },
          {
            path: 'comercial',
            element: <OrderListTable isComercialApproval />,
          },
        ],
      },
      // Products
      {
        path: 'produtos',
        element: <ErpProducts />,
        children: [
          { path: '', element: <ProductList /> },
          { path: ':productId/editar', element: <ProductEdit /> },
        ],
      },
      {
        path: 'conselhos',
        element: <CouncilsRootPage />,
        children: [
          { path: '', element: <CouncilList /> },
          { path: 'novo', element: <CouncilCreate /> },
          { path: ':councilId', element: <CouncilDetails /> },
          { path: ':councilId/editar', element: <CouncilEdit /> },
        ],
      },
    ],
  },
  {
    path: 'authentication',
    children: [
      {
        path: 'login',
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        ),
      },
      { path: 'login-unguarded', element: <Login /> },
      { path: 'password-recovery', element: <PasswordRecovery /> },
      { path: 'password-reset', element: <PasswordReset /> },
      {
        path: 'register',
        element: (
          <GuestGuard>
            <Register />
          </GuestGuard>
        ),
      },
      { path: 'register-unguarded', element: <Register /> },
      { path: 'verify-code', element: <VerifyCode /> },
    ],
  },
  {
    path: 'jetadmin',
    element: (
      <AuthGuard>
        <MinimalLayout />
      </AuthGuard>
    ),
    children: [{ path: 'vendas', element: <OrderMinimal /> }],
  },
  {
    path: '*',
    element: <MainLayout />,
    children: [
      { path: '', element: <Redirect to="/dashboard" /> },
      { path: '', element: <Home /> },
      { path: 'aviso-de-privacidade', element: <PrivacyPolicy /> },
      { path: '401', element: <AuthorizationRequired /> },
      { path: '404', element: <NotFound /> },
      { path: '500', element: <ServerError /> },
      { path: 'jetadmin-vendas', element: <OrderMinimal /> },
      { path: '*', element: <NotFound /> },
    ],
  },
  {
    path: 'checkout',
    element: <MainLayout />,
    children: [
      { path: ':orderId', element: <Checkout /> },
      { path: 'login/:orderId', element: <CheckoutLogin /> },
      { path: 'payment/:orderId', element: <CheckoutBillingChoice /> },
      { path: 'payment/credit-card/:orderId', element: <CreditCard /> },
      { path: 'payment/pix/:orderId', element: <Pix /> },
      { path: 'payment/bank-slip/:orderId', element: <BankSlip /> },
      { path: 'payment/confirm/:orderId', element: <ConfirmPayment /> },
      {
        path: 'confirm-bank-slip/:orderId',
        element: <ConfirmBankSlip />,
      },
    ],
  },
  {
    path: 'renegociacao',
    element: <MainLayout />,
    children: [{ path: ':dealID', element: <Renegociation /> }],
  },
  {
    path: 'carta',
    element: <RawLayout />,
    children: [{ path: ':dealID', element: <RenegociationCarta /> }],
  },
];

export default routes;
