/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  List,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import { AutoAwesomeMotion, Clear, Sell } from '@material-ui/icons';
import { useNavigate } from 'react-router';
import { ICustomer } from 'src/modules/customers/types/CustomerInterface';
import { UseQueryResult } from 'react-query';
import { ISource } from 'src/modules/customers/types/SourceInterface';
import { CustomGQLResult } from 'src/services/gqlService';
import { ErpProductsInterface } from 'src/modules/erpProducts/types/ErpProductsInterface';
import { ComboProductsInterface } from 'src/modules/erpProducts/services/getAllCombos';
import { SelectController } from 'src/components/forms/SelectController';
import MutatingButton from 'src/components/forms/MutatingButton';
import { isMobile } from 'react-device-detect';
import { addDays, addMonths } from 'date-fns';
import { DEFAULT_LOCALE } from 'src/config';
import { useMutationToast } from '../../../hooks/useMutationToast';
import { createOrder } from '../services/createOrder';
import { IOrderForm } from '../types/OrderFormInterface';
import CombosModal from '../components/CombosModal';
import { AutocompleteBusinessCreatable } from './AutocompleteBusinessCreateble';
import { RequestTypeInterface } from '../services/geAllRequestTypes';
import { ProviderInterface } from '../services/getAllProviders';
import { AutoCompleteForm } from './AutCompleteForm';
import { hasProductsInventory } from '../utils/productsInventory';
import { OrderFormRow } from './OrderFormRow';
// eslint-disable-next-line import/no-named-as-default
import OrderFormTotalRow from './OrderFormTotalRow';
import { IOrder } from '../types/OrderInterface';
import { updateOrder } from '../services/updateOrder';
import { findCustomerCPF } from '../utils/cpfOrCnpj';
import { IPrivateIndividualResponse } from '../types/IPrivateIndividualResponse';
import { ILegalPersonResponse } from '../types/ILegalPersonResponse';
import { ICheckAssertivaResponse } from '../utils/checkAssertiva';
import AdditionalDetails from '../components/AdditionalDetails';
import AssertivaVerificationCard from '../components/AssertivaVerificationCard';
import { assertivaApproval } from '../utils/approveCreateOrder';
import ScoreVerificationCard from '../components/ScoreVerificationCard';
import { HorizontalSteps } from '../components/Stepper';
import { ConfirmModal } from '../components/ConfirmModal';

interface OrderFormProps {
  products: ErpProductsInterface[];
  customers: ICustomer[];
  combos: ComboProductsInterface[];
  sources: ISource[];
  customersQuery: UseQueryResult<CustomGQLResult<ICustomer[]>, unknown>;
  types?: RequestTypeInterface[];
  providers?: ProviderInterface[];
  order?: IOrder;
}

const OrderForm: React.FC<OrderFormProps> = ({
  products,
  customers,
  combos,
  sources,
  customersQuery,
  types,
  providers,
  order,
}) => {
  const navigate = useNavigate();
  const isEditForm = !!order;
  const [openConfirmOrderModal, setOpenConfirmOrderModal] = useState(false);
  const [confirmed, setConfirmed] = useState(false);

  const mutateCreate = useMutationToast(createOrder, {
    onSuccess: (data) => {
      navigate(`/orcamentos/${data.data.id}`);
    },
  });

  const mutateEdit = useMutationToast(updateOrder, {
    onSuccess: (data) => {
      navigate(`/orcamentos/${data.data.id}`);
    },
  });

  const orderProducts = useMemo(() => {
    if (order) {
      return order.products.map((orderProduct) => {
        const product = products.find(
          (_product) => _product.id === orderProduct.product.id,
        );
        return {
          ...orderProduct,
          product,
        };
      });
    }
    return [];
  }, [order, products]);

  const methods = useForm<IOrderForm>({
    defaultValues: {
      products: orderProducts || [],
      pix_payment: order?.pix_payment || false,
      credit_card_payment: order?.credit_card_payment || false,
      bank_slip_payment: order?.bank_slip_payment || false,
      comment: order?.comment || '',
      customer: order?.customer.id || '',
      discount: order?.discount || undefined,
      off_percent: order?.off_percent || undefined,
      off_value: order?.off_value || undefined,
      erp_provider_id: order?.erp_provider_id || '',
      erp_request_type: order?.erp_request_type || '',
      installments: order?.installments || undefined,
      due_date: order?.due_date || addMonths(new Date(), 1).toISOString(),
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const { handleSubmit, control, watch, setValue } = methods;
  const [selectedCombo, setSelectedCombo] =
    React.useState<ComboProductsInterface>();

  const [productsList, setProductsList] = React.useState<
    ErpProductsInterface[]
  >([...products]);

  const onClickCategory = (category: string | undefined) => {
    setProductsList(
      products.filter((product) => product.gru_descr === category),
    );
  };

  const resetFilterCategory = () => {
    setProductsList(products);
  };

  const categoriesList = useMemo(() => {
    const _categoriesList = products.map((product) => product.gru_descr);
    const categoriesListUnique = [...new Set(_categoriesList)];
    return categoriesListUnique;
  }, [products]);

  const hasSelectedCategory = (category: string | undefined) =>
    productsList.some((product) => product.gru_descr === category);

  const { append, remove } = useFieldArray({
    control,
    name: 'products',
  });

  const selectedFormProducts = watch('products');

  const handleAddProduct = () => {
    if (selectedCombo !== undefined) {
      selectedCombo.products.forEach((product) => {
        const comboProducts = products.find(
          // @ts-ignore
          (p) => p.erp_prod_id === product.pro_id.toString(),
        );
        if (comboProducts !== undefined) {
          append({
            product: comboProducts,
            quantity: 1,
            off_percent: 0,
            off_value: 0,
          });
        }
      });
    }
  };

  const [open, setOpen] = React.useState(false);
  const [total, setTotal] = React.useState(0);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [individualResponse, setIndividualResponse] =
    React.useState<IPrivateIndividualResponse>();
  const [legalResponse, setLegalResponse] =
    React.useState<ILegalPersonResponse>();
  const [card, openCard] = React.useState(false);
  const [response, setResponse] = React.useState<ICheckAssertivaResponse>();
  const [openResponseModal, setOpenResponseModal] = React.useState(false);
  const [loading, isLoading] = React.useState(false);
  const [step, setStep] = React.useState(1);
  const [score, setScore] = React.useState(0);

  const customerId = watch('customer');

  const peopleResponse = individualResponse;
  const isPeopleResponse = peopleResponse !== undefined;

  useEffect(() => {
    setConfirmed(false);
    if (total > 3000000 && customerId) {
      isLoading(true);

      setTimeout(() => {
        isLoading(false);
      }, 2000);

      setStep(1);
      setScore(0);

      findCustomerCPF(
        customerId,
        customers,
        openCard,
        setIndividualResponse,
        setLegalResponse,
        setResponse,
      );
    }
  }, [total, customerId, customers]);

  const bankSlipPayment = watch('bank_slip_payment');
  const isBankSlipPayment = bankSlipPayment === true;

  const onSubmit = async (data: IOrderForm) => {
    const assertiva =
      total > 3000000 && isBankSlipPayment
        ? await assertivaApproval(response, isBankSlipPayment, score)
        : true;

    if (assertiva.valueOf() === true) {
      try {
        if (isEditForm) {
          mutateEdit.mutate({
            ...order,
            ...data,
          });
        } else {
          mutateCreate.mutate(data);
        }
      } catch (error: any) {
        console.log(error);
      }
    } else {
      console.log('não foi aprovado');
    }
  };

  return (
    <FormProvider {...methods}>
      <form>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <Card>
              <Box p={2}>
                <AutocompleteBusinessCreatable
                  control={control}
                  rules={{ required: true }}
                  name="customer"
                  size="small"
                  label="Para quem esse orçamento será feito?"
                  values={
                    customers?.map(({ id, name }) => ({
                      key: id,
                      label: name,
                    })) || []
                  }
                  onSuccess={() => customersQuery.refetch()}
                  setValue={setValue}
                  sources={sources}
                  defaultValue={order?.customer}
                />
              </Box>
            </Card>

            <Box mb={2} />

            <Card>
              <Box p={2}>
                <Grid container>
                  <Grid item xs={12}>
                    <SelectController
                      control={control}
                      rules={{ required: true }}
                      name="erp_request_type"
                      label="Tipo de pedido"
                      variant="outlined"
                      values={
                        types?.map((type) => ({
                          label: `${type.tpp_descr}`,
                          key: `${type.tpp_id}`,
                        })) || []
                      }
                    />
                  </Grid>
                </Grid>
              </Box>
            </Card>

            <Box mb={2} />

            <Card>
              <Box p={2}>
                <Grid container>
                  <Grid item xs={12}>
                    <AutoCompleteForm
                      control={control}
                      rules={{ required: false }}
                      name="erp_provider_id"
                      label="Parceiro"
                      variant="outlined"
                      values={
                        providers?.map((provider) => ({
                          label: `${provider.pes_id} -  ${provider.pes_razao}`,
                          key: `${provider.pes_id}`,
                        })) || []
                      }
                    />
                  </Grid>
                </Grid>
              </Box>
            </Card>

            <Box mb={2} />

            <Grid
              item
              xs={12}
              sx={{
                textAlign: 'center',
              }}
            >
              <CombosModal
                open={open}
                handleClose={handleClose}
                combos={combos}
                setSelectedCombo={setSelectedCombo}
                handleAddProduct={handleAddProduct}
              />
              <Button
                onClick={handleClickOpen}
                variant="contained"
                color="primary"
                size="small"
                startIcon={<AutoAwesomeMotion fontSize="small" />}
              >
                Abrir Combos
              </Button>
            </Grid>

            <Box mb={2} />

            <Card>
              <Box p={2}>
                <Grid container>
                  <Box sx={{ mt: 3 }}>
                    <Grid item xs={12}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Typography color="textSecondary" variant="overline">
                          Filtros Rápidos
                        </Typography>
                        <Button
                          onClick={() => {
                            resetFilterCategory();
                          }}
                          variant="outlined"
                          color="primary"
                          size="small"
                          endIcon={<Clear />}
                        >
                          Selecionar todos
                        </Button>
                      </Box>
                    </Grid>

                    <Box sx={{ mt: 1 }}>
                      {categoriesList.map((category) => (
                        <Button
                          variant={
                            hasSelectedCategory(category)
                              ? 'contained'
                              : 'outlined'
                          }
                          color="primary"
                          size="small"
                          sx={{
                            m: 1,
                          }}
                          onClick={() => {
                            onClickCategory(category);
                          }}
                        >
                          {category}
                        </Button>
                      ))}
                    </Box>
                  </Box>

                  <Grid item xs={12}>
                    <Box mt={2}>
                      <Autocomplete
                        multiple
                        id="products"
                        options={productsList}
                        getOptionLabel={(option) =>
                          hasProductsInventory(option)
                            ? `${option.pro_descr}`
                            : `${option.pro_descr} (Sem estoque)`
                        }
                        filterSelectedOptions
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="Selecione os produtos"
                          />
                        )}
                        noOptionsText="Nenhum produto encontrado. Confira se o filtro está correto."
                        // @ts-ignore
                        defaultValue={orderProducts.map((product) => ({
                          pro_id: product.product?.id,
                          pro_descr: product.product?.pro_descr,
                          pro_estoqueatual: product.product?.pro_estoqueatual,
                        }))}
                        disableClearable
                        onChange={(event, value, _reason, product) => {
                          if (_reason === 'removeOption') {
                            const index = value.findIndex(
                              (item) => item.id === product?.option.id,
                            );
                            remove(index);
                          } else {
                            append({
                              product: product?.option,
                              quantity: 1,
                              off_percent: 0,
                              off_value: 0,
                            });
                          }
                        }}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Card>

            <Box mt={1} />
            <Grid item>
              <List component="nav" aria-label="main mailbox folders">
                <>
                  <TableContainer component={Paper}>
                    <Table size="small" aria-label="a dense table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">Capa</TableCell>
                          <TableCell>Livro</TableCell>
                          <TableCell align="left">Preço Un.</TableCell>
                          <TableCell align="left">Qtd</TableCell>
                          <TableCell align="left">Desc. R$</TableCell>
                          <TableCell align="left">Desc. %</TableCell>
                          <TableCell align="left">Total</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {selectedFormProducts.map(({ product }, index) => (
                          <OrderFormRow
                            index={index}
                            product={product}
                            methods={methods}
                            key={`order-form-row-${product.id}`}
                          />
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              </List>
            </Grid>
          </Grid>

          {loading && (
            <Grid item xs={12} md={4}>
              <>
                <Box
                  mt={-1}
                  mb={1}
                  display="flex"
                  flexDirection="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Typography variant="h6">
                    Verificando cliente na assertiva
                  </Typography>
                </Box>
                <CircularProgress />
              </>
            </Grid>
          )}

          <Grid item xs={12} md={4}>
            {card === true && (
              <>
                <HorizontalSteps activeStep={step} />

                <AssertivaVerificationCard
                  openResponseModal={openResponseModal}
                  peopleResponse={peopleResponse}
                  response={response}
                  setOpenResponseModal={setOpenResponseModal}
                  openCard={openCard}
                  legalResponse={legalResponse}
                  isPeopleResponse={isPeopleResponse}
                />
                <ScoreVerificationCard
                  customerID={customerId}
                  customers={customers}
                  setStep={setStep}
                  score={score}
                  setScore={setScore}
                />
              </>
            )}

            <Card
              style={{
                marginTop: '10px',
              }}
            >
              <AdditionalDetails methods={methods} order={order} />
              <OrderFormTotalRow methods={methods} setTotal={setTotal} />

              {!isMobile && (
                <Box
                  mt={5}
                  mb={2}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <ConfirmModal
                    products={selectedFormProducts}
                    openModal={openConfirmOrderModal}
                    setOpenModal={setOpenConfirmOrderModal}
                    methods={methods}
                    setConfirmed={setConfirmed}
                  />

                  {confirmed && (
                    <MutatingButton
                      startIcon={<Sell fontSize="small" />}
                      variant="contained"
                      // disabled={!formState.isValid}
                      loadingText="Aguarde..."
                      onClick={handleSubmit(onSubmit)}
                      fullWidth
                    >
                      Incluir
                    </MutatingButton>
                  )}

                  {!confirmed && (
                    <MutatingButton
                      startIcon={<Sell fontSize="small" />}
                      variant="contained"
                      // disabled={!formState.isValid}
                      loadingText="Aguarde..."
                      onClick={() => {
                        setOpenConfirmOrderModal(true);
                      }}
                      fullWidth
                    >
                      Conferir
                    </MutatingButton>
                  )}
                </Box>
              )}
            </Card>

            {isMobile && (
              <Box mt={5} width="100%">
                {confirmed && (
                  <MutatingButton
                    startIcon={<Sell fontSize="small" />}
                    variant="contained"
                    fullWidth
                    // disabled={!formState.isValid}
                    loadingText="Aguarde..."
                    onClick={handleSubmit(onSubmit)}
                  >
                    Incluir
                  </MutatingButton>
                )}
                {!confirmed && (
                  <MutatingButton
                    startIcon={<Sell fontSize="small" />}
                    variant="contained"
                    fullWidth
                    // disabled={!formState.isValid}
                    loadingText="Aguarde..."
                    onClick={() => {
                      setOpenConfirmOrderModal(true);
                    }}
                  >
                    Conferir
                  </MutatingButton>
                )}
              </Box>
            )}
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default OrderForm;
