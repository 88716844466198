/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { IPrivateIndividualResponse } from '../types/IPrivateIndividualResponse';
import { ILegalPersonResponse } from '../types/ILegalPersonResponse';

interface ResponseModalInterface {
  open: boolean;
  handleClose: () => void;
  isLoading?: boolean;
  responseCPF: IPrivateIndividualResponse | undefined;
  responseCNPJ: ILegalPersonResponse | undefined;
}

const ResponseModal: React.FC<ResponseModalInterface> = ({
  open,
  handleClose,
  isLoading,
  responseCPF,
  responseCNPJ,
}) => (
  <Dialog
    open={open}
    keepMounted
    onClose={handleClose}
    aria-labelledby="alert-dialog-slide-title"
    aria-describedby="alert-dialog-slide-description"
    disableEscapeKeyDown={isLoading}
  >
    <DialogTitle
      style={{
        textAlign: 'center',
        color: 'secondary',
      }}
      id="alert-dialog-slide-title"
    >
      Confira os dados do seu cliente
    </DialogTitle>

    <DialogContentText id="alert-dialog-slide-description" ml={2} mr={2}>
      <p>
        <strong>
          Nome:{' '}
          {responseCPF?.resposta.dadosCadastrais.nome ||
            responseCNPJ?.resposta.dadosCadastrais.razaoSocial ||
            'Não encontrado'}
        </strong>
      </p>
      <p>
        <strong>
          CPF:{' '}
          {responseCPF?.resposta.dadosCadastrais.cpf ||
            responseCNPJ?.resposta.dadosCadastrais.cnpj ||
            'Não informado'}
        </strong>
      </p>
      <p>
        <strong>
          Data de nascimento:{' '}
          {responseCPF?.resposta.dadosCadastrais.dataNascimento ||
            'Não informado'}
        </strong>
      </p>
      <p>
        <strong>
          Sexo: {responseCPF?.resposta.dadosCadastrais.sexo || 'Não informado'}
        </strong>
      </p>
      <p>
        <strong>
          Nome da mãe:{' '}
          {responseCPF?.resposta.dadosCadastrais.maeNome || 'Não informado'}
        </strong>
      </p>
      <p>
        <strong>
          Nascimento da mãe:{' '}
          {responseCPF?.resposta.dadosCadastrais.maeDataNascimento ||
            'Não informado'}
        </strong>
      </p>
      <p>
        <strong>
          CEP:{' '}
          {responseCPF?.resposta.enderecos.length
            ? responseCPF?.resposta.enderecos[0].cep
            : responseCNPJ?.resposta.enderecos.length
            ? responseCNPJ?.resposta.enderecos[0].cep
            : 'Não informado'}
        </strong>
      </p>
      <p>
        <strong>Celular: Não informado</strong>
      </p>
      <p>
        <strong>
          Situação cadastral:{' '}
          {responseCPF?.resposta.dadosCadastrais.situacaoCadastral ||
            responseCNPJ?.resposta.dadosCadastrais.situacaoCadastral ||
            'Não informado'}
        </strong>
      </p>
    </DialogContentText>

    <DialogContent>
      <Typography
        variant="h6"
        style={{
          textAlign: 'center',
          color: 'red',
        }}
      >
        Utilização do dado pessoal para combate à fraude, visando à
        identificação correta ou confirmação de identidade.
      </Typography>
    </DialogContent>

    <DialogActions
      style={{
        justifyContent: 'space-around',
        marginBottom: '1rem',
      }}
    >
      <Button
        disabled={isLoading}
        onClick={handleClose}
        color="primary"
        variant="contained"
      >
        Voltar
      </Button>
    </DialogActions>
  </Dialog>
);

export default ResponseModal;
