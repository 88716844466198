import { gql } from '@apollo/client';
import { query, CustomGQLResult } from '../../../services/gqlService';
import { ISource } from '../types/SourceInterface';

export const getAllSources = async (): Promise<CustomGQLResult<ISource[]>> => {
  try {
    const result = await query<{ sources: ISource[] }>(
      gql`
        query GetAllSources {
          sources {
            id,
            slug,
            label,
          }
        }
      `
    );

    return { data: result.data.sources };
  } catch (error: any) {
    throw new Error(
      error?.message ? error.message : 'Ocorreu um erro, tente novamente.',
    );
  }
};
