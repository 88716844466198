import type { FC, ReactNode } from 'react';
import { Outlet } from 'react-router-dom';
import { experimentalStyled } from '@material-ui/core/styles';

interface MinimalLayoutProps {
  children?: ReactNode;
}

const MinimalLayoutRoot = experimentalStyled('div')(() => ({
  backgroundColor: '#F3F5F8',
  display: 'flex',
  height: '100%',
  overflow: 'hidden',
  width: '100%',
}));

const MinimalLayout: FC<MinimalLayoutProps> = () => (
  <MinimalLayoutRoot>
    <Outlet />
  </MinimalLayoutRoot>
);

export default MinimalLayout;
