import { InputAdornment, TextField } from '@material-ui/core';
import { useMemo } from 'react';
import {
  Control,
  DeepMap,
  DeepPartial,
  FieldError,
  RegisterOptions,
  useController,
  UseControllerProps,
} from 'react-hook-form';
import InputMask from 'react-input-mask';
import {
  convertCentsToLocale,
  convertMoneyToCents,
} from 'src/utils/moneyConverter';

/**
 * Extract from MDN docs
 *
 * @link https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#Form_%3Cinput%3E_types
 */
type FormInputTypes =
  | 'button'
  | 'checkbox'
  | 'color'
  | 'date'
  | 'datetime-local'
  | 'email'
  | 'file'
  | 'hidden'
  | 'image'
  | 'month'
  | 'number'
  | 'password'
  | 'radio'
  | 'range'
  | 'search'
  | 'submit'
  | 'tel'
  | 'text'
  | 'time'
  | 'url'
  | 'week';

interface TextfieldControllerInterface<T>
  extends Omit<UseControllerProps<any, any>, 'control' | 'rules' | 'name'> {
  placeholder?: string;
  control: Control<any>;
  name: keyof T;
  rules: Omit<
    RegisterOptions<any, any>,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs'
  >;
  label?: string;
  disabled?: boolean;
  startAdornment?: string;
  readonly?: boolean;
  variant?: 'filled' | 'outlined' | 'standard';
  helperMessage?: string;
  mask?: MaskInterface;
  type?: FormInputTypes;
  inputComponent?: any;
  multiline?: boolean;
  rows?: number;
  rowsMax?: number;
  size?: 'small' | 'medium';
  currency?: 'none' | 'cents' | 'float';
  isBill?: boolean;
}

/**
 * @param mask for numbers, use "9", for letters use "a", for everything use "*"
 * @param maskChar Character to cover unfilled parts of the mask.
 *    Default character is "_". If set to null or empty string, unfilled parts
 *    will be empty as in ordinary input.
 * @param formatChars Defines format characters with characters as a keys and
 *    corresponding RegExp strings as a values
 * @param alwaysShowMask Show mask when input is empty and has no focus
 * @param inputRef Use inputRef instead of ref if you need input node to manage
 *    focus, selection, etc.
 *
 */
interface MaskInterface {
  mask: string | string[];
  formatChars?: {};
  maskChar?: string;
  alwaysShowMask?: boolean;
  inputRef?: Function;
}

// export const TextfieldController: React.FC<TextfieldControllerInterface> = ({
export const TextfieldController = <T extends object>({
  control,
  name,
  rules,
  defaultValue,
  placeholder,
  label,
  startAdornment,
  mask,
  helperMessage,
  disabled = false,
  readonly = false,
  type,
  inputComponent,
  variant = 'outlined',
  multiline = false,
  rows = 1,
  size = 'medium',
  currency = 'none',
  isBill = false,
  ...props
}: TextfieldControllerInterface<T> & { children?: React.ReactNode }) => {
  const {
    field: { ref, ...fieldProps },
    fieldState: { error },
    formState: { isSubmitting },
  } = useController({
    name: String(name),
    control,
    rules,
    defaultValue,
  });

  const getValueFromCurrency = (value: string | number) => {
    // check if value is a string
    if (typeof value === 'string' && isBill) {
      return convertCentsToLocale(Number(value));
    }

    if (typeof value === 'string') {
      return value;
    }

    if (currency === 'cents') {
      return convertCentsToLocale(value);
    }

    if (currency === 'float') {
      return convertCentsToLocale(convertMoneyToCents(value));
    }

    return value;
  };

    const getMask: string | undefined = useMemo(() => {
      if (!mask?.mask) return undefined;
      if (typeof mask?.mask === 'string') {
        return mask.mask;
      }

      // return the biggest mask from array
      return mask.mask.reduce((a, b) => (a.length > b.length ? a : b));
    }, [mask]);

  return getMask ? (
    <InputMask
      mask={getMask}
      alwaysShowMask={mask?.alwaysShowMask || true}
      multiple
      disabled={isSubmitting || disabled}
      readOnly={readonly}
      {...fieldProps}
    >
      {/* @ts-ignore */}
      {(maskInputProps: Record<string, any>) => (
        <TextField
          key={`field-name-${fieldProps.name}`}
          {...maskInputProps}
          inputRef={ref}
          id={`field-${String(name)}`}
          disabled={isSubmitting || disabled}
          label={label}
          defaultValue={defaultValue}
          variant={variant}
          data-testid={`input-${String(name)}`}
          fullWidth
          error={!!error}
          helperText={getHelperMessage(error || helperMessage)}
          multiline={multiline}
          rows={rows}
          size={size}
          {...props}
          InputProps={{
            autoComplete: 'new-password',
            startAdornment: (startAdornment || startAdornment?.includes('undefined'))
              ? null
              : <InputAdornment position="start">{startAdornment}</InputAdornment>,
          }}
        />
      )}
    </InputMask>
  ) : (
    <TextField
      key={`field-name-${fieldProps.name}`}
      inputRef={ref}
      data-testid={`input-${String(name)}`}
      id={`field-${String(name)}`}
      placeholder={placeholder || ''}
      defaultValue={defaultValue}
      label={label}
      InputLabelProps={{ shrink: !!fieldProps.value }}
      disabled={isSubmitting || disabled}
      variant={variant}
      fullWidth
      type={type || 'text'}
      size={size}
      error={!!error}
      helperText={getHelperMessage(error || helperMessage)}
      multiline={multiline}
      rows={rows}
      InputProps={{
        // @ts-ignore
        inputComponent,
        readOnly: readonly,
        autoComplete: 'new-password',
        startAdornment: (
          <InputAdornment position="start">{startAdornment}</InputAdornment>
        ),
      }}
      {...props}
      {...fieldProps}
      value={
        currency !== 'none'
          ? getValueFromCurrency(fieldProps.value)
          : fieldProps.value
      }
    />
  );
};

export function getHelperMessage(
  message:
    | string
    | FieldError
    | DeepMap<DeepPartial<any>, FieldError>
    | undefined,
): string | undefined {
  if (!message) return undefined;
  if (typeof message === 'string') return message;
  if (message?.message) return message.message;

  const DEFAULT_MSG = 'Ooops, campo inválido!';

  switch (message?.type) {
    case 'max':
      return 'O campo ultrapassou o limite máximo de caracteres';

    case 'maxLength':
      return 'O campo ultrapassou o tamanho máximo';

    case 'min':
      return 'O campo não atingiu o limite mínimo de caracteres';

    case 'minLength':
      return 'O campo não atingiu o tamanho mínimo';

    case 'pattern':
      return 'O campo não segue o padrão necessário';

    case 'required':
      return 'O campo é obrigatório';

    case 'setValueAs':
      return DEFAULT_MSG;

    case 'shouldUnregister':
      return DEFAULT_MSG;

    case 'validate':
      return DEFAULT_MSG;

    case 'value':
      return DEFAULT_MSG;

    case 'valueAsDate':
      return 'O campo precisa ser uma data';

    case 'valueAsNumber':
      return 'O campo precisa ser um número';

    default:
      return DEFAULT_MSG;
  }
}
