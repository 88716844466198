import { ICreateCustomer } from '../services/createCustomer';

export const approveCreateCustomer = async (customer: ICreateCustomer) => {
  if (
    customer.billing_address === undefined ||
    customer.billing_address === null
  ) {
    throw new Error('O endereço de cobrança é obrigatório');
  }
  if (customer.billing_address.street.length > 50) {
    throw new Error('O campo rua não pode ter mais que 50 caracteres');
  }
  if (
    customer.delivery_address &&
    customer.delivery_address.street.length > 50
  ) {
    throw new Error('O campo rua não pode ter mais que 50 caracteres');
  }
  if (
    customer.delivery_address?.country === null ||
    customer.delivery_address?.country === undefined ||
    customer.delivery_address?.country === ''
  ) {
    throw new Error('O campo país é obrigatório');
  }
  if (
    customer.billing_address.country === null ||
    customer.billing_address.country === undefined ||
    customer.billing_address.country === ''
  ) {
    throw new Error('O campo país é obrigatório');
  }
};
