import { gql } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { format } from 'date-fns';
import { formatDate } from 'src/modules/orders/utils/validateBankSlipDate';
import { CustomGQLResult, mutate } from '../../../services/gqlService';
import { ICustomerForm } from '../forms/CustomerFormInterface';
import { ICustomer } from '../types/CustomerInterface';
import { approveCreateCustomer } from '../utils/approveCreateCustomer';
import { convertCustomerFormToCustomer } from './updateCustomer';
export interface ICreateCustomer
  extends Omit<Partial<ICustomer>, 'id' | 'source' | 'document'> {
  source: string;
  document?: string;
}

export const createCustomer = async (
  data: ICustomerForm,
): Promise<CustomGQLResult<ICustomer>> => {
  try {
    const customer: ICreateCustomer = {
      ...convertCustomerFormToCustomer(data),
      source: data.source,
    }

    if (customer.document === '') {
      customer.document = undefined;
    }

    await approveCreateCustomer(customer);

    const newDate =
      customer.birth_date === '__/__/____'
        ? null
        : formatDate(customer.birth_date);
    const dueDate = customer.birth_date ? newDate : null;

    const result = await mutate<{ createCustomer: ICustomer }>(
      gql`
        mutation CreateCustomer(
          $name: String!
          $email: String
          $cpf_cnpj: String
          $phone: String
          $photo: String
          $source: String!
          $document: String
          $delivery_address: CreateAddressInput
          $billing_address: CreateAddressInput
          $crm_cro: String
          $phone_2: String
          $social_media: String
          $birth_date: DateTime
          $certificate_photo: String
          $print_photo: String
          $id_card: String
          $origin: String
        ) {
          createCustomer(
            createCustomerInput: {
              name: $name
              email: $email
              cpf_cnpj: $cpf_cnpj
              phone: $phone
              photo: $photo
              source: $source
              delivery_address: $delivery_address
              billing_address: $billing_address
              crm_cro: $crm_cro
              phone_2: $phone_2
              social_media: $social_media
              birth_date: $birth_date
              id_card: $id_card
              document: $document
              certificate_photo: $certificate_photo
              print_photo: $print_photo
              origin: $origin
            }
          ) {
            id
            name
          }
        }
      `,
      {
        ...customer,
        delivery_address: customer.delivery_address?.zip_code
          ? customer.delivery_address
          : null,
        billing_address: customer.billing_address?.zip_code
          ? customer.billing_address
          : null,
        birth_date: customer.birth_date ? dueDate : null,
        origin: data.origin,
      },
    );

    if (result.errors) {
      Sentry.captureMessage('Erro ao criar cliente');
      Sentry.captureException(result.errors);
    }

    return { data: result.data.createCustomer };
  } catch (error: any) {
    Sentry.captureException(error);
    throw new Error(
      error?.message ? error.message : 'Ocorreu um erro, tente novamente.',
    );
  }
};
