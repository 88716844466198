import { axiosGET } from 'src/services/axiosService';
import { IPrivateIndividualResponse } from '../types/IPrivateIndividualResponse';

export const findByCpfAssertiva = async (
  cpf: string | undefined,
): Promise<IPrivateIndividualResponse> => {
  try {
    const endpoint = `${process.env.REACT_APP_PUBLIC_BACKEND}/assertiva-integration/cpf/${cpf}`;
    const response = await axiosGET<IPrivateIndividualResponse>(endpoint);

    return response.data;
  } catch (error: any) {
    throw new Error(
      error?.message ? error.message : 'Ocorreu um erro, tente novamente.',
    );
  }
};
