import { useState } from 'react';
import type { FC, ReactNode } from 'react';

import { Outlet } from 'react-router-dom';
import { experimentalStyled } from '@material-ui/core/styles';
import Footer from './Footer';
import MainNavbar from './MainNavbar';
import MainSidebar from './MainSidebar';

interface RawLayoutProps {
  children?: ReactNode;
}

const RawLayoutSyled = experimentalStyled('div')(({ theme }) => ({}));

const RawLayout: FC<RawLayoutProps> = ({ children }) => (
  <RawLayoutSyled>{children || <Outlet />}</RawLayoutSyled>
);

export default RawLayout;
