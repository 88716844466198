/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/naming-convention */
import { FC, useMemo } from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import { convertCentsToLocale } from 'src/utils/moneyConverter';

interface ConfirmModalOrderRowProps {
  imgUrl: string;
  name: string;
  priceUnit: number;
  quantity: number;
  discountPrice: number;
  discountPercent: number;
  totalPrice: number;

}

export const ConfirmModalOrderRow: FC<ConfirmModalOrderRowProps> = ({
  imgUrl,
  name,
  priceUnit,
  quantity,
  discountPrice,
  discountPercent,
  totalPrice,

}: ConfirmModalOrderRowProps) => (
  <TableRow>
    <TableCell component="th" scope="row">
      <img src={imgUrl} alt="" width="50px" />
    </TableCell>
    <TableCell component="th" scope="row">
      {name}
    </TableCell>
    <TableCell component="th" scope="row">
      {convertCentsToLocale(priceUnit)}
    </TableCell>
    <TableCell align="left">
      {quantity}
    </TableCell>
    <TableCell align="left">
      {convertCentsToLocale(discountPrice)}
    </TableCell>
    <TableCell align="left">
      {discountPercent}
    </TableCell>
    <TableCell align="left">
      {convertCentsToLocale(totalPrice)}
    </TableCell>
  </TableRow>
  );
