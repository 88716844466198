import React from 'react';
import { Box, Button, CircularProgress, ButtonProps } from '@material-ui/core';
import { useIsMutating } from 'react-query';

interface LoadingButtonInterface extends Omit<ButtonProps, 'href' | 'classes'> {
  loadingText?: string;
}

const MutatingButton: React.FC<LoadingButtonInterface> = ({
  children,
  loadingText,
  fullWidth,
  ...props
}) => {
  const isMutating = useIsMutating();

  return (
    <Box display="flex" alignItems="center">
      <Box position="relative" width={fullWidth ? '100%' : 'inherit'}>
        <Button
          variant="contained"
          color="primary"
          disabled={!!isMutating === true}
          fullWidth={fullWidth}
          {...props}
          endIcon={isMutating ? <CircularProgress size={12} /> : null}
        >
          {isMutating ? loadingText || children : children}
        </Button>
      </Box>
    </Box>
  );
};

export default MutatingButton;
