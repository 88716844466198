import {
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  FormHelperText,
} from '@material-ui/core';
import {
  Control,
  RegisterOptions,
  useController,
  UseControllerProps,
} from 'react-hook-form';
import { getHelperMessage } from './TextfieldController';

interface KeyLabel {
  key: string | number;
  label: string;
}

interface SelectControllerInterface
  extends Omit<UseControllerProps<any, any>, 'control' | 'rules'> {
  placeholder?: string;
  control: Control<any>;
  rules: Omit<
    RegisterOptions<any, any>,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs'
  >;
  values: KeyLabel[];
  label?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  variant?: 'filled' | 'outlined';
  helperMessage?: string;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const SelectController: React.FC<SelectControllerInterface> = ({
  control,
  name,
  rules,
  values,
  defaultValue,
  placeholder,
  label,
  helperMessage,
  fullWidth = true,
  disabled = false,
  variant = 'outlined',
}) => {
  const {
    field: { value, onChange, ...fieldProps },
    fieldState: { error },
    formState: { isSubmitting },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  });

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    onChange(event.target.value || '');
  };

  return (
    <FormControl
      sx={{ mb: '1em' }}
      error={!!error}
      variant={variant}
      fullWidth={fullWidth}
    >
      <InputLabel error={!!error}>{label}</InputLabel>
      <Select
        labelId="demo-mutiple-checkbox-label"
        id="demo-mutiple-checkbox"
        multiple={false}
        variant={variant}
        disabled={disabled || isSubmitting}
        label={label}
        onChange={handleChange}
        error={!!error}
        fullWidth
        value={value || undefined}
        renderValue={(str) => str ? values.find((obj) => obj.key === str)?.label : placeholder}
        displayEmpty
        MenuProps={MenuProps}
        {...fieldProps}
      >
        {values.map((keyValue) => (
          <MenuItem key={keyValue.key} value={keyValue.key}>
            {keyValue.label}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText error={!!error} id="my-helper-text">
        {getHelperMessage(error || helperMessage)}
      </FormHelperText>
    </FormControl>
  );
};
