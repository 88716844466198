import { gql } from '@apollo/client';
import { format } from 'date-fns';
import { formatDate } from 'src/modules/orders/utils/validateBankSlipDate';
import { CustomGQLResult, mutate } from '../../../services/gqlService';
import { ICustomerForm } from '../forms/CustomerFormInterface';
import { ICustomer } from '../types/CustomerInterface';

export interface IUpdateCustomer extends Omit<Partial<ICustomer>, 'source' | 'documents'> {
  source?: string;
  document?: string;
}

interface IUpdateCustomerParams {
  data: ICustomerForm;
  customer_id: string;
}

export const updateCustomer = async (
  params: IUpdateCustomerParams,
): Promise<CustomGQLResult<ICustomer>> => {
  const { customer_id, data } = params;
  
  const newDate = formatDate(data.birth_date);
  const dueDate = data.birth_date ? newDate : null;

  const customer: IUpdateCustomer = {
    id: customer_id,
    ...convertCustomerFormToCustomer(data),
  }

  if (data.document === '') customer.document = undefined;

  try {
    const result = await mutate<{ updateCustomer: ICustomer }>(
      gql`
        mutation UpdateCustomer(
          $id: String!
          $name: String
          $email: String
          $cpf_cnpj: String
          $phone: String
          $photo: String
          $source: String
          $document: String
          $crm_cro: String
          $phone_2: String
          $social_media: String
          $birth_date: DateTime
          $id_card: String
          $delivery_address: CreateAddressInput
          $billing_address: CreateAddressInput
          $origin: String
          $certificate_photo: String
          $print_photo: String
          $document_login_photo: String
        ) {
          updateCustomer(
            updateCustomerInput: {
              id: $id
              name: $name
              email: $email
              cpf_cnpj: $cpf_cnpj
              phone_2: $phone_2
              birth_date: $birth_date
              id_card: $id_card
              phone: $phone
              social_media: $social_media
              photo: $photo
              source: $source
              delivery_address: $delivery_address
              billing_address: $billing_address
              crm_cro: $crm_cro
              document: $document
              origin: $origin
              certificate_photo: $certificate_photo
              print_photo: $print_photo
              document_login_photo: $document_login_photo
            }
          ) {
            id
          }
        }
      `,
      {
        ...customer,
        delivery_address: customer.delivery_address?.zip_code
          ? customer.delivery_address
          : null,
        billing_address: customer.billing_address?.zip_code
          ? customer.billing_address
          : null,
        birth_date: customer.birth_date ? dueDate : null,
        document: data.document ? data.document : null,
        origin: data.origin,
        certificate_photo: data.certificate_photo,
        print_photo: data.print_photo,
        document_login_photo: data.document_login_photo,
      },
    );

    console.log('🙋‍♂️ - ' + result);

    return { data: result.data.updateCustomer };
  } catch (error: any) {
    console.error('🙋‍♂️❌ - ' + error);
    throw new Error(
      error?.message ? error.message : 'Ocorreu um erro, tente novamente.',
    );
  }
};

export function convertCustomerFormToCustomer(data: ICustomerForm): IUpdateCustomer {
  const crm = `${data.council}/${data.council_state}-${data.council_number}`
  const isSameAddress = data.is_same_address;

  return {
    name: data.name,
    email: data.email,
    cpf_cnpj: data.cpf_cnpj,
    phone: data.phone,
    phone_2: data.phone_2,
    birth_date: data.birth_date,
    id_card: data.id_card,
    social_media: data.social_media,
    source: data.source,
    delivery_address: data.delivery_address,
    billing_address: isSameAddress ? data.delivery_address : {
      ...data.billing_address,
      street: `${data.billing_address.street}`,
      district: `${data.billing_address.district}`,
      city: `${data.billing_address.city}`,
      state: `${data.billing_address.state}`,
      zip_code: `${data.billing_address.zip_code}`,
      country: `${data.billing_address.country}`,
      number: `${data.billing_address.number}`,
    },
    crm_cro: crm,
    document: data.document,
    certificate_photo: data.certificate_photo,
    print_photo: data.print_photo,
  };
}