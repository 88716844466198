/* eslint-disable react/jsx-no-bind */
import { Box, Button, Modal, Typography } from '@material-ui/core';
import { useRef } from 'react';
import * as Sentry from '@sentry/react';
import SignaturePad from 'react-signature-pad-wrapper';

interface SignaturePadModalInterface {
  openModal: boolean;
  signatureURL: string;
  handleCloseModal: () => void;
  handleCloseAndConfirmModal: () => void;
  setSignatureURL: (newValue: any) => void;
}

export const SignaturePadModal = ({
  openModal,
  handleCloseModal,
  handleCloseAndConfirmModal,
  setSignatureURL,
  signatureURL,
}: SignaturePadModalInterface) => {
  const sigPAD = useRef<any>({});
  const clear = () => {
    try {
      setSignatureURL('');
      sigPAD.current.clear();
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const save = () => {
    if (!sigPAD.current) return;
    setSignatureURL(sigPAD.current.toDataURL('image/png', 1.0));
  };

  const handleSaveAndClose = () => {
    save();
    handleCloseAndConfirmModal();
  };

  return (
    <Modal
      open={openModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { xs: '100vw', md: 600 },
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: { xs: 2, md: 4 },
        }}
      >
        <Box className="App">
          <Box pb={2}>
            <Typography>Adicionar assinatura</Typography>
          </Box>
          <Box style={{ border: '2px dashed black' }} p={2}>
            {signatureURL.length > 0 && (
              <img
                src={signatureURL}
                style={{ width: '100%', height: 'auto', border: '1px solid' }}
                alt=""
              />
            )}
            {signatureURL.length === 0 && (
              <SignaturePad
                ref={sigPAD}
                options={{
                  minWidth: 2,
                  maxWidth: 4,
                  penColor: 'rgb(0, 0, 0)',
                  backgroundColor: '#fff',
                }}
              />
            )}

            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center  "
              alignItems="center"
            >
              <Button onClick={clear}>Limpar assinatura</Button>
            </Box>
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="right"
          alignItems="center"
        >
          <Button
            color="secondary"
            size="large"
            variant="contained"
            sx={{
              mb: 3,
              mt: 3,
              mr: 2,
            }}
            onClick={handleCloseModal}
          >
            Cancelar
          </Button>
          {signatureURL.length > 0 && (
            <Button
              color="primary"
              size="large"
              variant="contained"
              sx={{
                mb: 3,
                mt: 3,
              }}
              onClick={handleSaveAndClose}
            >
              Confirmar
            </Button>
          )}
          {signatureURL.length === 0 && (
            <Button
              color="primary"
              size="large"
              variant="contained"
              sx={{
                mb: 3,
                mt: 3,
              }}
              onClick={save}
            >
              Salvar
            </Button>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default SignaturePadModal;
