import { ICustomer } from 'src/modules/customers/types/CustomerInterface';
import Swal from 'sweetalert2';
import { findByCnpjAssertiva } from '../services/findByCnpjAssertiva';
import { findByCpfAssertiva } from '../services/findByCpfAssertiva';
import { ILegalPersonResponse } from '../types/ILegalPersonResponse';
import { IPrivateIndividualResponse } from '../types/IPrivateIndividualResponse';
import {
  CheckAssertivaCPF,
  CheckAssertivaCNPJ,
  ICheckAssertivaResponse,
} from './checkAssertiva';

export function cpfOrCnpj(value: string | undefined) {
  if (!value) return null;

  if (value.length === 11) {
    return 'CPF';
  }

  if (value.length === 14) {
    return 'CNPJ';
  }

  return 'INVALIDO';
}

function CheckData(data: IPrivateIndividualResponse, customer: ICustomer) {
  if (data.resposta.dadosCadastrais.cpf !== customer.cpf_cnpj) {
    alert('CPF não confere');
  }
  if (data.resposta.enderecos[0].cep !== customer.delivery_address?.zip_code) {
    alert('CEP não confere');
  }
  if (data.resposta.dadosCadastrais.nome !== customer.name) {
    alert('Nome não confere');
  }
}

export const findCustomerCPF = async (
  id: string | undefined,
  customers: ICustomer[],
  openCard: React.Dispatch<React.SetStateAction<boolean>>,
  setIndividualResponse: React.Dispatch<
    React.SetStateAction<IPrivateIndividualResponse | undefined>
  >,
  setLegalResponse: React.Dispatch<
    React.SetStateAction<ILegalPersonResponse | undefined>
  >,
  setResponse: React.Dispatch<
    React.SetStateAction<ICheckAssertivaResponse | undefined>
  >,
) => {
  const customer = customers.find((_customer) => _customer.id === id);

  if (customer) {
    const customerSelected = cpfOrCnpj(customer?.cpf_cnpj);

    if (customerSelected === 'CPF') {
      const response = await findByCpfAssertiva(customer?.cpf_cnpj);

      if (response) {
        const responseCPF = await CheckAssertivaCPF(customer, response);

        openCard(true);

        setTimeout(() => 10000);
        setResponse(responseCPF);

        setIndividualResponse(response);
      }
    }
    if (customerSelected === 'CNPJ') {
      const response = await findByCnpjAssertiva(customer?.cpf_cnpj);

      const responseCNPJ = await CheckAssertivaCNPJ(customer, response);

      setResponse(responseCNPJ);

      setLegalResponse(response);
      setTimeout(() => openCard(true), 1000);
    }
    if (customerSelected === 'INVALIDO') {
      Swal.fire({
        title: 'Erro!',
        text: 'CPF ou CNPJ inválido',
        icon: 'error',
        confirmButtonText: 'Ok',
      });
      openCard(false);
    }
  }
  return null;
};
