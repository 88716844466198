import {
  MutationFunction,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from 'react-query';
import toast from 'react-hot-toast';
import { CustomGQLResult } from 'src/services/gqlService';

const SUCCESS_MSG = 'Operação realizada com sucesso!';
const ERROR_MSG = 'Ocorreu um problema, tente novamente.';

export function useMutationToast<
  TData = CustomGQLResult<unknown>,
  TError = Error,
  TVariables = void,
  TContext = unknown,
>(
  mutationFn: MutationFunction<CustomGQLResult<TData>, TVariables>,
  options?: UseMutationOptions<
    CustomGQLResult<TData>,
    TError,
    TVariables,
    TContext
  >,
): UseMutationResult<CustomGQLResult<TData>, TError, TVariables, TContext> {
  const mutation = useMutation<
    CustomGQLResult<TData>,
    TError,
    TVariables,
    TContext
  >(mutationFn, {
    ...options,
    onSuccess: (data, variables, context) => {
      toast.success(SUCCESS_MSG);

      if (options?.onSuccess) options.onSuccess(data, variables, context);
    },
    onError: (error: TError, variables, context) => {
      let msg: string = ERROR_MSG;

      if (error === null || error === undefined) msg = ERROR_MSG;
      else if (typeof error === 'string') msg = error;
      else if (typeof error === 'object') {
        const castedError: any = error;
        msg = castedError?.message || ERROR_MSG;
      }

      toast.error(msg);

      if (options?.onError) options.onError(error, variables, context);
    },
    onSettled: () => {
      toast.dismiss('save');
    },
  });

  return mutation;
}
