import React, { createContext, useCallback, useEffect, useReducer, useState } from 'react';
import { useFullscreen } from 'rooks';
import Reducer, { LayoutAction } from './LayoutReducer';
import { layoutInitialState } from './LayoutInitializer';
import { LayoutContextInterface } from './LayoutInterface';

export type LayoutDispatchType = React.Dispatch<LayoutAction>;

interface LayoutProviderInterface {
  tvMode: boolean;
  setTvMode: React.Dispatch<React.SetStateAction<boolean>>;
  toggleFullScreen: () => void;
  stateLayout: LayoutContextInterface;
  dispatchLayout: LayoutDispatchType;
}

export const LayoutContext = createContext<LayoutProviderInterface>({
  stateLayout: layoutInitialState,
  tvMode: false,
  setTvMode: () => console.warn('setTvMode not ready'),
  toggleFullScreen: () => console.warn('toggleFullScreen not ready'),
  dispatchLayout: () => console.warn('LayoutDispatch not ready'),
});

const LayoutProvider: React.FC = ({ children }) => {
  const fullscreen = useFullscreen();
  const [tvMode, setTvMode] = useState<boolean>(false);
  const [stateLayout, dispatchLayout] = useReducer(Reducer, layoutInitialState);

  const toggleFullScreen = useCallback(
    () => {
      const isFullScreen = !!fullscreen?.isFullscreenEnabled;

      if (!isFullScreen) {
        fullscreen?.enableFullscreen();
      } else {
        fullscreen?.disableFullscreen();
      }
    },
    [fullscreen],
  );

  useEffect(() => {
    setTvMode(!!fullscreen?.isFullscreenEnabled);
  }, [fullscreen]);

  const globals = {
    stateLayout,
    dispatchLayout,
    tvMode,
    setTvMode,
    toggleFullScreen,
  };

  return (
    <LayoutContext.Provider value={globals}>{children}</LayoutContext.Provider>
  );
};

export default LayoutProvider;
