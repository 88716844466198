import React, { useEffect, useMemo } from 'react';
import { Box, Divider, Typography } from '@material-ui/core';
import { convertCentsToLocale } from 'src/utils/moneyConverter';
import { UseFormReturn } from 'react-hook-form';
import {
  calculateAllProductsDiscount,
  calculateDiscountOnSubtotal,
} from '../utils/calculateDiscount';
import { IOrderForm } from '../types/OrderFormInterface';
import { calculateSubtotal } from '../utils/calculateSubtotal';

interface OrderFormTotalRowProps {
  methods: UseFormReturn<IOrderForm, any>;
  setTotal: React.Dispatch<React.SetStateAction<number>>;
}

export const OrderFormTotalRow: React.FC<OrderFormTotalRowProps> = ({
  methods,
  setTotal,
}) => {
  const { watch } = methods;

  const selectedFormProducts = watch('products');
  const offPercentOnOrder = watch('off_percent') || 0;
  const offValueOnOrder = watch('off_value') || 0;

  const subtotal = calculateSubtotal(selectedFormProducts);
  const discountProducts = calculateAllProductsDiscount(selectedFormProducts);
  const subtotal1 = useMemo(
    () => subtotal - discountProducts,
    [subtotal, discountProducts],
  );

  const discountOrder = calculateDiscountOnSubtotal(
    subtotal1,
    offPercentOnOrder,
    offValueOnOrder,
  );

  const discountTotal = discountOrder + discountProducts;
  const total = subtotal - discountTotal;

  useEffect(() => {
    setTotal(total);
  }, [setTotal, total]);

  return (
    <Box p={2}>
      <Box
        mt={-1}
        mb={1}
        display="flex"
        flexDirection="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Typography variant="h6">Detalhes do pedido</Typography>

        {/* <Tooltip title="Desconto aplicado sobre o valor total da venda">
          <IconButton>
            <Help />
          </IconButton>
        </Tooltip> */}
      </Box>

      {/* <TextfieldController
        control={control}
        name="off_percent"
        label="Cupom em %"
        placeholder="Cupom em %"
        rules={{
          min: 0,
          max: 100,
          onChange(event) {
            setDiscountValue(parseFloat(event.target.value));
          },
        }}
      />

      <TextfieldController
        control={control}
        name="off_value"
        label=""
        placeholder=""
        currency="cents"
        inputComponent={CurrencyInput}
        rules={{
          onChange(event) {
            setDiscountPercent(event.target.value);
          },
        }}
      /> */}

      <Box mb={2} mt={1}>
        <Divider />
      </Box>

      <Box display="flex" flexDirection="row" alignItems="center">
        <Typography variant="body1">Subtotal:</Typography>
        <Typography variant="h6" color="GrayText">
          <Box ml={1}>{convertCentsToLocale(subtotal1)}</Box>
        </Typography>
      </Box>

      <Box display="flex" flexDirection="row" alignItems="center">
        <Typography variant="body1">Descontos:</Typography>
        <Typography variant="h6" color="GrayText">
          <Box ml={1}>{convertCentsToLocale(discountProducts)}</Box>
        </Typography>
      </Box>

      {/* <Box display="flex" flexDirection="row" alignItems="center">
        <Typography variant="body1">Cupom:</Typography>
        <Typography variant="h6" color="GrayText">
          <Box ml={1}>{convertCentsToLocale(discountOrder)}</Box>
        </Typography>
      </Box> */}

      <Box height={10} />

      <Box display="flex" flexDirection="row" alignItems="flex-end">
        <Typography fontWeight={500} variant="h6">
          Total:
        </Typography>
        <Typography fontWeight={500} variant="h5">
          <Box ml={1}>{convertCentsToLocale(total)}</Box>
        </Typography>
      </Box>
    </Box>
  );
};

export default OrderFormTotalRow;
