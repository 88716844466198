import React, { forwardRef } from 'react';
import CurrencyInputLib from 'react-currency-input';

// const defaultMaskOptions = {
//   prefix: 'R$ ',
//   suffix: '',
//   includeThousandsSeparator: true,
//   thousandsSeparatorSymbol: '.',
//   allowDecimal: true,
//   decimalSymbol: ',',
//   decimalLimit: 2, // how many digits allowed after the decimal
//   integerLimit: 12, // limit length of integer numbers
//   allowNegative: false,
//   allowLeadingZeroes: true,
// };

const CurrencyInput = forwardRef((props, ref) => (
  <CurrencyInputLib
    prefix="R$ "
    decimalSeparator=","
    thousandSeparator="."
    inputType="tel"
    ref={ref}
    {...props}
  />
));

export default CurrencyInput;
