import { axiosGET } from 'src/services/axiosService';
import { ILegalPersonResponse } from '../types/ILegalPersonResponse';

export const findByCnpjAssertiva = async (
  cnpj: string | undefined,
): Promise<ILegalPersonResponse> => {
  try {
    const endpoint = `${process.env.REACT_APP_PUBLIC_BACKEND}/assertiva-integration/cnpj/${cnpj}`;
    const response = await axiosGET<ILegalPersonResponse>(endpoint);
    return response.data;
  } catch (error: any) {
    throw new Error(
      error?.message ? error.message : 'Ocorreu um erro, tente novamente.',
    );
  }
};
