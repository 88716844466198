import { gql } from '@apollo/client';
import { query, CustomGQLResult } from '../../../services/gqlService';
import { ICustomer } from '../types/CustomerInterface';

export const getAllCustomers = async (): Promise<
  CustomGQLResult<ICustomer[]>
> => {
  try {
    const result = await query<{ customers: ICustomer[] }>(
      gql`
        query GetAllCustomers {
          customers {
            id
            name
            cpf_cnpj
            email
            phone
            photo
            phone_2
            birth_date
            id_card
            approval_status {
              label
              slug
            }
            user {
              name
            }
            delivery_address {
              id
              street
              district
              zip_code
              city
              state
              complement
              number
            }
            billing_address {
              id
              street
              district
              zip_code
              city
              state
              complement
              number
            }
          }
        }
      `,
    );

    return { data: result.data.customers };
  } catch (error: any) {
    throw new Error(
      error?.message ? error.message : 'Ocorreu um erro, tente novamente.',
    );
  }
};
