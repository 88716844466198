import {
  ApprovalStatusEnum,
  PaymentStatusEnum,
} from '../types/ApprovalStatusInterface';
import { IOrderForm } from '../types/OrderFormInterface';

export function ApprovalUpdate(order: IOrderForm) {
  if (order.approval_status.slug === ApprovalStatusEnum.CONFIRMED) {
    throw new Error('Não é possível alterar um pedido confirmado');
  }
  if (order.payment_status.slug === PaymentStatusEnum.PAY) {
    throw new Error('Não é possível alterar um pedido pago');
  }
  if (
    order.pix_payment === false &&
    order.credit_card_payment === false &&
    order.bank_slip_payment === false
  ) {
    throw new Error(
      'É necessário selecionar pelo menos uma forma de pagamento',
    );
  }
  if (order?.products.length === 0) {
    throw new Error('É necessário adicionar pelo menos um produto');
  }
}
