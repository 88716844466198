import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Box, Container } from '@material-ui/core';

import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router';
import RenegociationSignature from 'src/components/RenegociationSignature';
import SignatureTermsModal from 'src/components/checkout/Payment/SignatureTermsModal';
import Swal from 'sweetalert2';
import {
  confirmRenegotiation,
  getRenegociationByDealId,
} from 'src/services/jetadmin.service';
import LoadingScreen from 'src/components/LoadingScreen';
import EmptyPlaceholder from 'src/components/layouts/EmptyPlaceholder';

const MAX_RETRIES = 5;
const RETRY_DELAY_MS = 2000;

const Renegociation: React.FC = () => {
  const [isLoadingMutation, setIsLoadingMutation] = useState<boolean>(false);
  const [retries, setRetries] = useState<number>(0);
  const [signatureURL, setSignatureURL] = useState<string>('');
  const { dealID } = useParams();

  const renegotiation = useQuery(
    ['renegotiation', `${dealID}`],
    () => getRenegociationByDealId({ dealId: String(dealID) }),
    {
      enabled: !!dealID,
      onSuccess: (data) => {},
      onError(err) {
        console.log('renegotiation error', err);
      },
      retry: 3,
      retryDelay: 1000,
    },
  );

  const confirmRenegociation = useMutation(confirmRenegotiation, {
    onSuccess: (data) => {
      Swal.fire({
        title:
          'Sucesso! Sua dívida foi renegociada. Você receberá um e-mail com os detalhes.',
        showCancelButton: false,
        showCloseButton: true,
        allowOutsideClick: () => !Swal.isLoading(),
        showConfirmButton: true,
        confirmButtonText: 'Ok',
      }).then((result) => {
        if (result.isConfirmed) {
          console.log('Redirecionando...');
          window.location.href = 'https://loja.editoranapoleao.com.br';
        }
      });
    },
  });

  const handleConfirm = (retryCount = 0) => {
    console.debug('handleConfirm');
    setIsLoadingMutation(true);
    confirmRenegociation.mutate(
      {
        dealId: String(dealID),
        base64: signatureURL,
      },
      {
        onSuccess(data, variables, context) {
          setIsLoadingMutation(false);
          setRetries(0); // Reset retries on success
          Swal.fire({
            title:
              'Sucesso! Sua dívida foi renegociada. Você receberá um e-mail com os detalhes.',
            showCancelButton: false,
            showCloseButton: true,
            allowOutsideClick: () => !Swal.isLoading(),
          });
        },
        onError(error, variables, context) {
          console.debug(`Error number ${retryCount} of ${MAX_RETRIES}`);
          if (retryCount < MAX_RETRIES) {
            setTimeout(() => {
              handleConfirm(retryCount + 1); // Increment retry count here
            }, RETRY_DELAY_MS);
          } else {
            setIsLoadingMutation(false);
            setRetries(0); // Reset retries when max retries are hit
            Swal.fire({
              title: 'Ops! Não foi possível renegociar sua dívida.',
              text:
                (error as unknown as any)?.message ||
                'Tente novamente mais tarde',
              icon: 'error',
              showCancelButton: false,
              showCloseButton: true,
              allowOutsideClick: () => !Swal.isLoading(),
            });
          }
        },
      },
    );
  };

  return (
    <>
      <Helmet>
        <title>Renegociação | Napoleão</title>
      </Helmet>

      <Box
        sx={{
          backgroundColor: 'background.paper',
          minHeight: '100%',
          py: 3,
        }}
      >
        {isLoadingMutation && <LoadingScreen />}
        <Container style={{ display: isLoadingMutation ? 'none' : 'block' }}>
          {renegotiation.isError && (
            <EmptyPlaceholder
              title="Não foi possível carregar a renegociação"
              subtitle={
                (renegotiation?.error as { message: string })?.message ||
                'Tente novamente mais tarde'
              }
            />
          )}
          {renegotiation.isLoading && <LoadingScreen />}
          {renegotiation.data && !renegotiation.isLoading && (
            <>
              <SignatureTermsModal renegociation={renegotiation.data} />
              <RenegociationSignature
                handleConfirm={handleConfirm}
                signatureURL={signatureURL}
                setSignatureURL={setSignatureURL}
                renegocation={undefined}
                loading={renegotiation.isLoading}
              />
            </>
          )}
        </Container>
      </Box>
    </>
  );
};

export default Renegociation;
