// @ts-nocheck
import type { FC } from 'react';
import { AppBar, Box, Divider, Toolbar } from '@material-ui/core';
import LogoAlt from './LogoAlt';

interface MainNavbarProps {
  onSidebarMobileOpen?: () => void;
}

const MainNavbar: FC<MainNavbarProps> = () => (
  <AppBar elevation={0} sx={{}}>
    <Toolbar sx={{ minHeight: 64 }}>
      <Box sx={{ flexGrow: 1 }} />
      <LogoAlt
        sx={{
          display: {
            md: 'inline',
            xs: 'none',
          },
          height: '40px',
          width: '40px',
        }}
      />
      <Box sx={{ flexGrow: 1 }} />
    </Toolbar>
    <Divider />
  </AppBar>
);

export default MainNavbar;
