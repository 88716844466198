import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { TextfieldController } from 'src/components/forms/TextfieldController';
import { useMutationToast } from 'src/hooks/useMutationToast';
import {
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core';
import { KeyLabel } from 'src/components/forms/AutocompleteController';
import {
  createCustomer,
  ICreateCustomer,
} from 'src/modules/customers/services/createCustomer';
import { SelectController } from 'src/components/forms/SelectController';
import { ISource } from 'src/modules/customers/types/SourceInterface';

interface AutocompleteBusinessCreatableFormInterface {
  fieldValue: string;
  handleClose: () => void;
  updateValues: (newValue: KeyLabel) => void;
  sources: ISource[];
}

const AutocompleteBusinessCreatableForm: React.FC<
  AutocompleteBusinessCreatableFormInterface
> = ({ fieldValue, handleClose, updateValues, sources }) => {
  const mutateCreate = useMutationToast(createCustomer, {
    onSuccess: ({ data }) => {
      updateValues({ key: data.id, label: data.name });
      handleClose();
    },
  });

  const { control, setValue } = useForm({
    defaultValues: {
      name: fieldValue,
      email: '',
      phone: '',
      cpf_cnpj: '',
      source: '',
      documents: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    setValue('name', fieldValue);
  }, [fieldValue, setValue]);

  const handleCreateBusinessContact = (data: ICreateCustomer) =>
    // @ts-ignore
    mutateCreate.mutate(data);

  return (
    <>
      <form>
        <DialogTitle id="form-dialog-title-business-contact">
          Novo Cliente
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Para cadastrar um novo cliente, precisamos pelo menos dos dados
            abaixo.
          </DialogContentText>
          <TextfieldController
            control={control}
            name="name"
            label="Nome"
            disabled={mutateCreate.isLoading}
            rules={{
              required: true,
            }}
          />
          <TextfieldController
            control={control}
            name="email"
            type="email"
            label="Email"
            rules={{
              required: true,
            }}
          />
          <TextfieldController
            control={control}
            name="cpf_cnpj"
            type="text"
            label="CPF/CNPJ"
            rules={{ required: true, minLength: 11, maxLength: 14 }}
          />
          <TextfieldController
            control={control}
            name="phone"
            type="text"
            label="Telefone"
            mask={{ mask: '(99) 99999-9999' }}
            rules={{
              required: true,
            }}
          />
          <SelectController
            control={control}
            rules={{ required: true }}
            name="source"
            label="Onde o cliente foi prospectado?"
            variant="outlined"
            values={sources?.map(({ id, label }) => ({ key: id, label })) || []}
          />
          <DialogContentText>
            Não se preocupe, você pode adicionar mais informações depois!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={mutateCreate.isLoading}
            onClick={handleClose}
            variant="text"
          >
            Cancelar
          </Button>
          <Button
            disabled={mutateCreate.isLoading}
            type="button"
            color="primary"
            // onClick={(e) => handleSubmit(onSubmit)(e)}
          >
            Criar
          </Button>
        </DialogActions>
      </form>
    </>
  );
};

export default AutocompleteBusinessCreatableForm;
