import { GetRenegotiationInfo } from 'src/types/jetadmin-renegotiation';
import { axiosGET } from './axiosService';
import axios from 'axios';

export interface DealResponse {
  id: number;
  deal_name: string;
  deal_type: string;
  contact: string;
  actio_pes_id: number;
  actio_usu_id: number;
  billing_option: string;
  rating: null | string;
  created_billing_asaas: boolean;
  amount: null | number;
  comments: null | string;
  installment_count: null | number;
  json_deal: {
    divisaoCobranca: {
      numero_de_vendas: number;
      vendas: Record<string, any[]>;
      itens: Array<{
        venda: string;
        itens: string[];
      }>;
    };
    calculoValores: {
      valor_parcelas_inadimplentes: number;
      valor_parcelas_vincendas: number;
      valor_final: number;
      opcao1: {
        numero_parcelas: number;
        valor_final: number;
        valor_parcela: null | number;
      };
      opcao2: {
        numero_parcelas: number;
        valor_final: number;
        valor_parcela: number;
      };
    };
    contatos: {
      Telefone: string;
      Celular: string;
      Email: string;
      'Social Media': string;
    };
  };
}

export const getRenegociationByDealId = async ({
  dealId,
}: {
  dealId: string;
}): Promise<GetRenegotiationInfo> => {
  try {
    const endpoint = `${process.env.REACT_APP_PUBLIC_BACKEND}/renegotiation/${dealId}`;

    const response = await axios.get<GetRenegotiationInfo>(endpoint);

    return response.data;
  } catch (error: any) {
    throw new Error(error.response.data.message);
  }
};

export const confirmRenegotiation = async ({
  dealId,
  base64,
}: {
  dealId: string;
  base64: string;
}): Promise<GetRenegotiationInfo> => {
  try {
    const endpoint = `${process.env.REACT_APP_PUBLIC_BACKEND}/renegotiation/confirm`;

    const response = await axios.post<GetRenegotiationInfo>(endpoint, {
      deal_id: Number(dealId),
      base_64_signature: base64,
    });

    return response.data;
  } catch (error: any) {
    throw new Error(
      error?.response?.data?.message || 'Ocorreu um problema, chame a Napoleão',
    );
  }
};
