/* eslint-disable array-callback-return */
/* eslint-disable max-len */
/* eslint-disable import/no-named-as-default */
import { Box, Modal, Paper, Table, TableCell, TableContainer, TableRow, Typography, TableHead, TableBody, Button, Checkbox } from '@material-ui/core';
import { useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { convertCentsToLocale } from 'src/utils/moneyConverter';
import { IOrderForm } from '../types/OrderFormInterface';
import { IOrderProduct } from '../types/OrderProductInterface';
import { calculateAllProductsDiscount } from '../utils/calculateDiscount';
import { calculateSubtotal } from '../utils/calculateSubtotal';
import { ConfirmModalOrderRow } from './ConfirmModalOrderRow';

interface ConfirmModalProps {
  products: IOrderProduct[];
  openModal: boolean;
  setOpenModal: (bool: boolean) => void;
  methods: UseFormReturn<IOrderForm, any>;
  setConfirmed: (bool: boolean) => void;
}
export const ConfirmModal = ({
  products,
  openModal,
  setOpenModal,
  methods,
  setConfirmed,
}: ConfirmModalProps) => {
  const calculateProductsTotal = (itemProducts: IOrderProduct[]) => {
    let productsLength = 0;
    itemProducts.map((item: IOrderProduct) => {
      productsLength += Number(item.quantity);
    });
    return productsLength;
  };
  const [checked, setChecked] = useState(false);
  const { watch } = methods;
  const subtotal = calculateSubtotal(products);
  const discountProducts = calculateAllProductsDiscount(products);
  const total = subtotal - discountProducts;
  const totalProduts = calculateProductsTotal(products);
  const pix = watch('pix_payment') === true;
  const creditcard = watch('credit_card_payment') === true;
  const bankslip = watch('bank_slip_payment') === true;
  return (

    <Modal
      open={openModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{
          position: 'absolute' as 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { xs: '100vw', md: 600 },
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: { xs: 2, md: 4 },
          overflowY: 'scroll',
          maxHeight: '95vh',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
      }}
      >
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          textAlign="center"
          pb={2}
        >

          <Typography color="primary" variant="h5" fontWeight="bold">Conferir Orçamento</Typography>
        </Box>
        <Box>
          {/* <Box>
          <Typography>SCARDUA - IDEAL FACE</Typography>
          <Typography>R$ 750,00</Typography>
          <Typography>R$ 637,50</Typography>
          <Typography>Quantidade: 1</Typography>
          <Typography>Desconto: 15%</Typography>
        </Box> */}
          <TableContainer component={Paper}>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Capa</TableCell>
                  <TableCell>Produto</TableCell>
                  <TableCell align="left">Preço Un.</TableCell>
                  <TableCell align="left">Qtd</TableCell>
                  <TableCell align="left">Desc. R$</TableCell>
                  <TableCell align="left">Desc. %</TableCell>
                  <TableCell align="left">Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                products.map((item: IOrderProduct) => {
                  const totalPrice = (item.product.prov_valor - Number(item.off_value)) * item.quantity;
                  return (
                    <ConfirmModalOrderRow
                      imgUrl={item.image || ''}
                      name={item.product.pro_descr}
                      priceUnit={item.product.prov_valor}
                      quantity={item.quantity}
                      discountPrice={Number(Number(item.off_value).toFixed(2))}
                      discountPercent={Number(item.off_percent)}
                      totalPrice={totalPrice}
                    />
                  );
                  })
              }

              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        <Box pt={4} pb={4}>

          <Typography>Método: {pix && 'Pix'} {creditcard && 'Crédito'} {bankslip && 'Boleto'}</Typography>

          <Typography>Total: {totalProduts} itens</Typography>
          <Typography>Subtotal: {convertCentsToLocale(subtotal)}</Typography>
          <Typography>Desconto sob o total: {convertCentsToLocale(discountProducts)}</Typography>
          <Typography>Total: {convertCentsToLocale(total)}</Typography>

        </Box>
        <Box display="flex" flexDirection="row" alignItems="center">

          <Checkbox checked={checked} onClick={() => setChecked(!checked)} />
          <Typography>Tudo Certo!</Typography>
        </Box>
        <Box display="flex">

          <Box>
            <Button
              onClick={() => {
                setChecked(false);
                setOpenModal(false);
              }}
            >
              Volter para editar
            </Button>

          </Box>
          <Box ml="auto">

            <Button
              disabled={!checked}
              onClick={() => {
                setConfirmed(true);
                setOpenModal(false);
              }}
            >
              Confirmar
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
