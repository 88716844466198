/* eslint-disable react/jsx-no-duplicate-props */
import {
  Chip,
  Autocomplete,
  TextField,
  createFilterOptions,
} from '@material-ui/core';
import {
  Control,
  RegisterOptions,
  useController,
  UseControllerProps,
} from 'react-hook-form';
import { getHelperMessage } from 'src/components/forms/TextfieldController';

interface KeyLabel {
  key: string | number;
  label: string;
}

interface AutoCompleteFormInterface<T>
  extends Omit<UseControllerProps<any, any>, 'control' | 'rules' | 'name'> {
  name: keyof T;
  control: Control<any>;
  rules: Omit<
    RegisterOptions<any, any>,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs'
  >;
  values: KeyLabel[];
  label?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  variant?: 'filled' | 'outlined';
  helperMessage?: string;
  size?: 'small' | 'medium';
  defaultValue?: KeyLabel | null;
  renderInputAddon?: JSX.Element;
  multiple?: boolean;
}

const filter = createFilterOptions<KeyLabel>();

// export const AutoCompleteForm: React.FC<AutoCompleteFormInterface> = ({
export const AutoCompleteForm = <T extends object>({
  control,
  name,
  rules,
  values,
  label,
  helperMessage,
  multiple = false,
  size = 'medium',
}: AutoCompleteFormInterface<T> & { children?: React.ReactNode }) => {
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    name: String(name),
    control,
    rules,
  });

  return (
    <Autocomplete
      key={`autocomplete-${String(name)}`}
      options={values}
      defaultValue={value}
      // @ts-ignore
      autoComplete="new-password"
      autoHighlight={false}
      autoSelect={false}
      multiple={multiple}
      getOptionLabel={(option) => {
        if (!option) return '';
        if (Array.isArray(option) && option.length === 0) return '';

        if (typeof option === 'string') {
          const selected = values?.find((_value) => _value.key === option);
          return selected?.label || option;
        }
        if (!option.key) {
          return option.label.toString();
        }
        return option.label.toString();
      }}
      fullWidth
      size={size}
      noOptionsText="Nenhum resultado"
      onInputChange={(event, change) => {
        const locatedValue = values.find(
          (_value) => _value.label === change,
        )?.key;
        onChange(locatedValue);
      }}
      // onChange={(event, keyLabel) => {
      //   if (typeof keyLabel === 'string') {
      //     setTimeout(() => {
      //       setInternalValue({ key: '', label: keyLabel });
      //     });
      //   } else {
      //     setInternalValue(keyLabel);
      //   }
      // }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params) as KeyLabel[];

        return filtered;
      }}
      renderTags={(options) =>
        options.map((option) => (
          <Chip
            variant="outlined"
            size={size}
            key={`chip-${option.key}`}
            label={option.label}
          />
        ))
      }
      renderInput={(params) => (
        <>
          <TextField
            key={`textfield-${String(name)}`}
            {...params}
            inputProps={{
              ...params.inputProps,
            }}
            autoComplete="new-password"
            label={label}
            size={size}
            variant="outlined"
            error={!!error}
            helperText={getHelperMessage(error || helperMessage)}
          />
        </>
      )}
    />
  );
};
